import { Outlet } from "react-router-dom";
import { createUseStyles } from 'react-jss'
import Navigation from "./Navigation/Navigation";

const Layout = () => {
  // 
  // ───  STYLE DEFINITION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      width: "100%",
      display: "flex"
    },
    main: {
      width: 'calc(100% - 330px)',
      maxWidth: 1200,
      minHeight: "calc(100vh - 151px)",
      margin: '80px auto 0',
      transform: 'translateX(125px)'
    }
  })

  const classes = useStyle()

  return <>
    <Navigation />
    <div className={classes.main}>
      <main className="App">
        <Outlet />
      </main>
    </div>
  </>
}

export default Layout
