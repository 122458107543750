// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Slider from '@mui/material/Slider';
import { autoSize } from '../../contexts/Utils';
const { v4 } = require('uuid');


// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const UuidGenerator = () => {
  const textAreaElement = useRef();

  const [number, setNumber] = useState(1)

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      color: 'var(--text-color)',
      maxWidth: 800,
      margin: 'auto',
    },
    container: {
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      borderColor: 'transparent',
      padding: 20,
      background: 'var(--block-color)',
    },
    textarea: {
      textAlign: 'center',
      width: 'calc(100% - 20px)',
      padding: '5px 10px',
      color: 'var(--text-color)',
      outline: 'none',
      fontFamily: 'Open-sans, sans-serif',
      lineHeight: 1.5,
      background: 'var(--block-color)',
      border: '1px solid var(--colored-element-color)',
      '&:focus': {
        border: '1px solid var(--colored-element-color)',
        background: 'rgba(255, 177, 0,0.2)'
      }
    },
    rangeContainer: {
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center',
      gap: 20
    },
    formTitles: {
      minWidth: 100
    }
  })

  const classes = useStyle();

  // 
  // ─── GENERATE UUIDV4 ELEMENTS TO DISPLAY ───────────────────────────────────────
  //
  let uuidv4 = "";
  for (let i = 0; i < number; i++) {
    uuidv4 += v4() + `${(i === 0 && number === 1) || (i === number - 1) ? '' : '\r\n'
      }`;
  }

  // 
  // ─── INCREASE NUMBER OF UUIDV4 ELEMENTS ───────────────────────────────────────
  //
  const handleNumber = (e) => {
    setNumber(e.target.value);
    autoSize(textAreaElement.current)
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    <div className='tools-header'>
      <h1>UUIDs v4 generator</h1>
      <p>Un identifiant unique universel (UUID) est un numéro de 128 bits utilisé pour identifier les informations dans les systèmes informatiques. Le nombre d'UUID possibles est de 16^32, soit 2^128 ou environ 3,4x10^38 (ce qui est beaucoup !).</p>
    </div>
    <div className={classes.container}>
      <div className={classes.rangeContainer}>
        <span className={classes.formTitles}>Quantité</span>
        <Slider value={number} onChange={handleNumber} valueLabelDisplay="auto" min={1} max={50} />
      </div>
      <textarea ref={textAreaElement} className={classes.textarea} rows='1' onChange={autoSize} value={uuidv4} readOnly></textarea>
    </div>
  </div >
};

export default UuidGenerator;