// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import Api from '../../contexts/Api'
import { formatTime } from '../../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ProjectDetail = (props) => {
  const projectParams = useParams();
  const [project, setProject] = useState();

  // 
  // ─── GET PROJECTS TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/projects',
        query: {
          project_id: projectParams.project_id,
          detail: true,
          time_tracking: true
        },
        method: "GET"
      }

      const getProject = await Api(params)
      if (getProject.success) {
        setProject(getProject.data)
      }
    }
    if (!project) {
      fetchData()
    }
  }, [project, projectParams])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    title: {
      color: 'var(--text-color)'
    },
    subtitle: {
      color: 'var(--text-color)'
    },
    card: {
      padding: 20,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
    },
    timeTrackingContainer: {
      display: 'flex',
      gap: 20,
      alignItems: 'center',
      color: 'var(--text-color)'
    },
    timeTrackingUser: {
      fontWeight: 'bold',
      fontSize: 20
    },
    timeTrackingWrapper: {
      display: 'flex',
      gap: 20,
      flexWrap: 'wrap'
    },
    timeTrackingContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },
    thumbnail: {
      width: 50
    }
  })

  const classes = useStyle()

  return project && <div>
    <h1 className={classes.title}>Détail d'un projet</h1>
    {
      props.role === 'super-admin' && project.time_tracking && <>
        <h2 className={classes.subtitle}>Time Tracking</h2>
        <div className={classes.timeTrackingWrapper}>
          {Object.entries(project.time_tracking).map(([key, value]) => {
            let user = window._DATA.users.filter(user => user.user_id === key)
            return <div className={classes.card + ' ' + classes.timeTrackingContainer}>
              <img className={classes.thumbnail} src={`/assets/img/team/${user[0].firstname.toLowerCase()}.jpg`} alt="" />
              <div className={classes.timeTrackingContent}>
                <span className={classes.timeTrackingUser}>{user[0].firstname}</span>
                <span>{formatTime(value.duration)}</span>
              </div>
            </div>
          })}
        </div>
      </>
    }
  </div>
};

export default ProjectDetail;