// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

// 
// ─── TOOLS ICONS ───────────────────────────────────────
//
import NotesIcon from '@mui/icons-material/Notes';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Tools = () => {

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0px, 1fr))',
      gap: 12,
      '@media screen and (max-width:1350px)': {
        gridTemplateColumns: 'repeat(2, minmax(0px, 1fr))',
      },
      '@media screen and (max-width:950px)': {
        gridTemplateColumns: 'repeat(1, minmax(0px, 1fr))',
      }
    },
    card: {
      color: 'var(--text-color)',
      maxWidth: 800,
      margin: 'auto',
      padding: '30px 24px',
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      textDecoration: 'none',
      border: '1px solid transparent',
      '&:hover': {
        border: '1px solid var(--colored-element-color)'
      }
    },
    icon: {
      width: '40px !important',
      height: '40px !important',
      opacity: 0.4
    },
    title: {
      fontWeight: 'bold'
    },
    description: {
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      display: '-webkit-inline-box',
      opacity: 0.6
    }
  })

  const classes = useStyle();

  const tools = [
    {
      icon: NotesIcon,
      title: "Lorem ipsum generator",
      description: "Le lorem ipsum est un texte de remplacement couramment utilisé pour démontrer la forme visuelle d'un document ou d'une police de caractères sans s'appuyer sur un contenu significatif.",
      url: "/outils/tools/lorem-ipsum-generateur"
    },
    {
      icon: FingerprintIcon,
      title: "UUIDs v4 generator",
      description: "Un identifiant unique universel (UUID) est un numéro de 128 bits utilisé pour identifier les informations dans les systèmes informatiques. Le nombre d'UUID possibles est de 16^32, soit 2^128 ou environ 3,4x10^38 (ce qui est beaucoup !).",
      url: "/outils/tools/uuid-generator"
    },
    {
      icon: DescriptionOutlinedIcon,
      title: "Base64 string encoder/decoder",
      description: "Il suffit de coder et de décoder une chaîne de caractères dans sa représentation base64.",
      url: "/outils/tools/base64-string-converter"
    },
    {
      icon: LockOutlinedIcon,
      title: "Bcrypt",
      description: "Hachage et comparaison de chaînes de texte à l'aide de bcrypt. Bcrypt est une fonction de hachage de mot de passe basée sur le chiffrement Blowfish.",
      url: "/outils/tools/bcrypt"
    },
    {
      icon: QrCodeIcon,
      title: "QR Code generator",
      description: "Générez et téléchargez le code QR d'une url ou d'un simple texte et personnalisez les couleurs d'arrière-plan et de premier plan.",
      url: "/outils/tools/qrcode"
    },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    {tools.map((tool) => {
      let Icon = tool.icon;
      return <Link to={tool.url} className={classes.card}>
        <Icon className={classes.icon} />
        <span className={classes.title}>{tool.title}</span>
        <span className={classes.description}>{tool.description}</span>
      </Link>
    })}
  </div>
};

export default Tools;