// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProjectManage from '../../containers/ProjectManage';
import Api from '../../contexts/Api'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ProjectEdit = () => {
  const projectParams = useParams()
  const [project, setProject] = useState()

  // 
  // ─── GET WEBSITE TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/projects',
        query: {
          project_id: projectParams.project_id
        },
        method: "GET"
      }

      const getProject = await Api(params)
      if (getProject.success) {
        setProject(getProject.data)
      }
    }
    if (!project) {
      fetchData()
    }
  }, [project, projectParams])

  return <>
    {project && <ProjectManage defaults={project} update={true} />}
  </>
};

export default ProjectEdit;