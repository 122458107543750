// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InventoryManage from '../../containers/InventoryManage';
import Api from '../../contexts/Api'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const InventoryEdit = () => {
  const inventoryParams = useParams()
  const [inventory, setInventory] = useState()

  // 
  // ─── GET INVENTORY ELEMENT TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/inventory',
        query: {
          inventory_id: inventoryParams.inventory_id
        },
        method: "GET"
      }

      const getInventory = await Api(params)
      if (getInventory.success) {
        setInventory(getInventory.data)
      }
    }
    if (!inventory) {
      fetchData()
    }
  }, [inventory, inventoryParams])

  return <>
    {inventory && <InventoryManage defaults={inventory} update={true} />}
  </>
};

export default InventoryEdit;