// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Api from '../contexts/Api';
import Formfields from "../components/Formfields";
import { prepareFormData, getValue } from '../contexts/Utils';
import Button from '@mui/material/Button';
import { useNotification } from '../components/UseNotification';
import ArrowBack from '../components/ArrowBack';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const QuoteManage = (props) => {
  const { notification } = useNotification()
  let navigate = useNavigate();
  let defaults = props.defaults || {}

  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);
  const [clientActive, setClientActive] = useState({});
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [offers, setOffers] = useState([]);
  const [offerActive, setOfferActive] = useState({});
  const [quoteList, setQuoteList] = useState([])

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'myForm' });

    if (data.errors) {
      setErrors(data.errors)
    } else {

      if (props.update) {
        data.offer_id = defaults.offer_id
      }

      const params = {
        data,
        endpoint: '/quotes',
        method: props.update ? 'PUT' : 'POST'
      }

      const createQuote = await Api(params)
      notification({ variant: createQuote.success ? 'success' : 'error', message: createQuote.message })

      if (createQuote.success) {
        return navigate("/gestion/offres");
      }
    }
  }

  // 
  // ─── RÉCPÉRATION DES USERNAME/ID DES CLIENTS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/clients',
        method: "GET"
      }
      const getClients = await Api(params)
      if (getClients.success) {
        setClients(getClients.data)
      }
    }
    if (!clients.length) { fetchData() }

  }, [clients])

  const handleClientActive = (e) => {
    setClientActive(clients.filter(client => client.client_id === e)[0])
  }

  // 
  // ─── RÉCPÉRATION DES OFFRES ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: "/offers",
        method: "GET"
      }

      const getOffers = await Api(params)
      if (getOffers.success) {
        setOffers(getOffers.data)
      }
    }
    if (openOfferModal) {
      fetchData()
    }
  }, [openOfferModal])

  const handleOffersActive = (e) => {
    const selectedOffer = offers.filter(offer => offer.offer_id === e)[0];
    setOfferActive(selectedOffer);
  }

  const handleOffers = () => {
    let data = prepareFormData({ formId: 'offers' }).fields;
    setQuoteList([...quoteList, data]);
    setOfferActive({});
    setOpenOfferModal(false);
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0px, 1fr))',
      gap: 20
    },
    card: {
      flex: 1,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 20,
      borderRadius: 20,
      '&>div:not(:first-child)': {
        marginTop: 20
      }
    },
    submit: {
      margin: '20px auto 10px !important'
    },
    dialogContent: {
      paddingTop: '20px !important'
    },
    quoteListContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
  const classes = useStyle();

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    {
      title: 'Mon titre',
      fields: [
        { component: "file", name: "logo", label: "Logo" },
        { component: "text", name: "title", label: "Titre" },
        { component: "text", name: "logo", label: "Facture N°" },
        { component: "text", name: "logo", label: "Date" },
        { component: "text", name: "logo", label: "Objet" },
      ]
    },
    {
      title: "Informations de la société",
      fields: [
        { component: "text", name: "title", label: "Raison sociale" },
        { component: "text", name: "logo", label: "Adresse" },
        { component: "text", name: "logo", label: "Code postal" },
        { component: "text", name: "logo", label: "Ville" },
        { component: "text", name: "logo", label: "Pays" },
        { component: "text", name: "logo", label: "Email" },
        { component: "text", name: "logo", label: "Site internet" },
        { component: "text", name: "logo", label: "Siret" },
      ]
    },
    {
      title: "Informations du client",
      fields: [
        { type: "select", component: "select", name: "client_id", label: "Username du client", data: clients, default: defaults.client_id || '-', dataLabel: 'username', dataValue: "client_id", handleChange: handleClientActive },
        { component: "text", name: "title", label: "Raison sociale", default: clientActive.company },
        { component: "text", name: "logo", label: "Nom", default: clientActive.name },
        { component: "text", name: "logo", label: "Prénom", default: clientActive.firstname },
        { component: "text", name: "logo", label: "Adresse", default: clientActive.address },
        { component: "text", name: "logo", label: "Code postal", default: clientActive.cp },
        { component: "text", name: "logo", label: "Ville", default: clientActive.city },
        { component: "text", name: "logo", label: "Téléphone", default: clientActive.phone },
        { component: "text", name: "logo", label: "Email", default: clientActive.email },
        { component: "text", name: "logo", label: "Numéro Intracommunautaire", default: clientActive.tva },
        { component: "text", name: "logo", label: "Siret", default: clientActive.siret },
      ]
    }
  ]

  const offerFields = [
    {
      fields: [
        { type: "select", component: "select", name: "offer_id", label: "Offre", data: offers, default: defaults.offer_id || '-', dataLabel: 'name', dataValue: "offer_id", handleChange: handleOffersActive },
        { component: "text", name: "reference", label: "Référence", default: offerActive.reference },
        { component: "text", name: "name", label: "Titre", default: offerActive.name },
        { component: "text", name: "description", label: "Description", default: offerActive.description },
        { component: "number", name: "quantity", label: "Quantité", default: offerActive.description },
        { component: "number", name: "discount", label: "Réduction", default: offerActive.description },
        { component: "text", name: "price", label: "Prix", default: offerActive.price },
        { component: "text", name: "tva", label: "TVA", default: offerActive.tva },
      ]
    }
  ]

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <div>
    <ArrowBack link='/gestion/devis' />
    <form onSubmit={handleChange} className={classes.form} name="myForm">
      <div className={classes.container}>
        {formFields.map((element, index) => {
          return <div key={index} className={classes.card}>
            <h2>{element.title}</h2>
            {element.fields.map((el) => {
              if (el.hasOwnProperty('cond') && !el.cond) return true
              if (defaults[el.name] && !el.default) el.default = defaults[el.name]

              // 
              // ─── DISPLAY FIELDS ───────────────────────────────────────
              //
              return <Formfields key={el.name} field={{ ...el, error: getValue(errors, [el.name, 'message']) }} slug={props.slug} />
            })}
          </div>
        })}
        <div className={classes.card}>
          <h2>Liste des points</h2>
          {quoteList.map((element) => {
            return <div className={classes.quoteListContainer}>
              <span>{element.name}</span>
              <span>{element.quantity}</span>
              <span>{element.discount}%</span>
              <span>{element.price}€</span>
              <span>{element.price - element.price * (element.discount / 100)}€</span>
            </div>
          })}
          <Button variant="contained" className={classes.submit} onClick={() => setOpenOfferModal(true)}>Ajouter une nouvelle offre</Button>
        </div>
      </div>
      <Button variant="contained" className={classes.submit} onClick={handleChange}>Enregistrer</Button>
    </form>
    <Dialog
      open={openOfferModal}
      onClose={() => { setOpenOfferModal(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Choisir une offre</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form name="offers">
          {offerFields.map((element, index) => {
            return <div key={index} className={classes.card}>
              {element.fields.map((el) => {
                if (el.hasOwnProperty('cond') && !el.cond) return true
                if (defaults[el.name] && !el.default) el.default = defaults[el.name]

                // 
                // ─── DISPLAY FIELDS ───────────────────────────────────────
                //
                return <Formfields key={el.name} field={{ ...el, error: getValue(errors, [el.name, 'message']) }} slug={props.slug} />
              })}
            </div>
          })}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setOpenOfferModal(false) }}>Revenir à la facturation</Button>
        <Button onClick={handleOffers} variant='contained' autoFocus>Ajouter</Button>
      </DialogActions>
    </Dialog>
  </div >
};

export default QuoteManage;