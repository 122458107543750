import React, { useState } from "react";
import Router from "./contexts/Router";
import { createUseStyles } from 'react-jss'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ThemeToggle from './components/ThemeToggle';

const App = () => {
  const [theme, setTheme] = useState(localStorage.getItem('olwe_theme') || "light")

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        light: '#FFB100',
        main: '#FFB100',
        dark: '#FFB100',
        contrastText: '#FFF',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        light: '#FFB100',
        main: '#FFB100',
        dark: '#FFB100',
        contrastText: '#FFF',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

  const handleThemeChange = (state) => {
    setTheme(state)
  }
  // 
  // ───  STYLE DEFINITION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      width: "100%",
      display: "flex"
    },
    main: {
      width: 'calc(100% - 330px)',
      maxWidth: 1200,
      minHeight: "calc(100vh - 151px)",
      margin: '80px auto 0',
      transform: 'translateX(125px)'
    }
  })

  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
    <ThemeToggle handleChange={handleThemeChange} />
    <div className={classes.wrapper}>
      <Router />
    </div>
  </ThemeProvider>
}

export default App;
