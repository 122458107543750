// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { getValue, formatDate } from '../../contexts/Utils';
import Api from '../../contexts/Api';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Deadlines = () => {
  const [services, setServices] = useState({});

  // 
  // ─── GET INVENTORY ELEMENT TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/ovh',
        method: "GET"
      }

      const getOvhServices = await Api(params)
      if (getOvhServices.success) {

        const allowedServicesRoutes = {
          '/domain/{serviceName}': 'Nom de domaine',
          '/hosting/web/{serviceName}': 'Hébergement mutualisé',
          '/vps/{serviceName}': 'VPS'
        }

        let categories = {
          "Nom de domaine": [],
          "VPS": [],
          "Hébergement mutualisé": []
        }

        getOvhServices.data.map((value) => {
          const serviceRoutes = getValue(value, ['route', 'path'])
          if (Object.keys(allowedServicesRoutes).includes(serviceRoutes)) {
            categories[allowedServicesRoutes[serviceRoutes]].push(value)
          }
        })

        setServices(categories)
      }
    }
    if (!Object.keys(services).length) {
      fetchData()
    }
  }, [services])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 40,
      color: 'var(--text-color)',
      marginBottom: 40
    },
    cardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20
    },
    card: {
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 10,
      background: 'var(--block-color)',
      width: 'calc(50% - 40px)',
    },
    title: {
      fontWeight: 'bold'
    },
    categoryTitle: {
      fontSize: '1.6em',
      fontWeight: 'bold',
      marginBottom: 20,
      display: 'block'
    },
    infos: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 6,
      '& span': {
        color: 'var(--colored-element-color)'
      }
    }
  })

  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    {Boolean(Object.keys(services).length) && Object.entries(services).map(([key, service], index) => {
      return <div key={index}>
        <span className={classes.categoryTitle}>{key}</span>
        <div className={classes.cardContainer}>
          {service.map((value) => {
            return <div key={value.serviceId} className={classes.card}>
              <span className={classes.title}>{value.resource.displayName}</span>
              <div className={classes.infos}>
                <div>Date d'expiration : <span>{formatDate({ time: value.billing.expirationDate, display: 'date' })}</span></div>
                <div>Prix : <span>{getValue(value, ['billing', 'pricing', 'price', 'text'])}</span></div>
              </div>
            </div>
          })}
        </div>
      </div>
    })}
  </div>
};

export default Deadlines;