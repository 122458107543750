// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Api from '../contexts/Api';
import Formfields from "../components/Formfields";
import { prepareFormData } from '../contexts/Utils';
import Button from '@mui/material/Button';
import { useNotification } from '../components/UseNotification';
import ArrowBack from '../components/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const ClientManage = (props) => {
  let defaults = props.defaults || {};
  let navigate = useNavigate();

  const [userType, setUserType] = useState(defaults.hasOwnProperty('type') ? defaults.type : 1);
  const [open, setOpen] = useState(false);
  const [companyData, setCompanyData] = useState({})

  const { notification } = useNotification();

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'myForm' })

    const params = {
      data,
      endpoint: '/clients',
      method: props.update ? 'PUT' : 'POST'
    }

    const createClient = await Api(params)
    notification({ variant: createClient.success ? 'success' : 'error', message: createClient.message })

    if (createClient.success) {
      return navigate("/gestion/clients");
    }
  }

  // 
  // ─── HANDLE APPOINTMENT FORM SUBMIT ───────────────────────────────────────
  //
  const handleAppointment = async () => {
    let data = prepareFormData({ formId: 'appointment_form' })
    data.fields.client_id = defaults.client_id;

    const params = {
      data,
      endpoint: '/appointments',
    }

    const createAppointment = await Api(params)
    notification({ variant: createAppointment.success ? 'success' : 'error', message: createAppointment.message })

    if (createAppointment.success) {
      return navigate("/gestion/clients");
    }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      marginBottom: 40
    },
    container: {
      display: 'flex',
      gap: 20
    },
    card: {
      flex: 1,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 20,
      borderRadius: 20,
      '&>div:not(:first-child)': {
        marginTop: 20
      }
    },
    submit: {
      margin: '20px auto 10px !important'
    },
    findClient: {
      color: 'var(--colored-element-color)',
      fontSize: 14,
      marginBottom: 30,
      cursor: 'pointer'
    },
    clientType: {
      alignItems: 'center',
      marginBottom: 20
    },
    dialogContent: {
      paddingTop: '20px !important'
    },
    appointmentContainer: {
      marginTop: 20
    }
  })
  const classes = useStyle();

  // 
  // ─── HANDLE DIALOG STATE ───────────────────────────────────────
  //
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 
  // ─── GET COMPANY INFOS BY SIRET ───────────────────────────────────────
  //
  const getCompanyInfos = async () => {

    let data = prepareFormData({ formId: 'company_infos' })

    const params = {
      data,
      endpoint: '/external_api/pappers',
    }

    const companyData = await Api(params);
    if (companyData.success) {
      setCompanyData(companyData.data);
      handleClose();
    } else {
      notification({ variant: companyData.success ? 'success' : 'error', message: companyData.message })
    }
  }

  const findClient = <div className={classes.findClient} onClick={handleClickOpen}>Trouver le numéro de siret</div>

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    {
      fields: [

        { type: "text", component: "text", name: "company", label: "Raison sociale" },
        { type: "text", component: "text", name: "siret", label: "SIRET" },
        { type: "text", component: "text", name: "siren", label: "SIREN" },
        { custom: true, component: findClient },
      ],
      cond: userType
    }, {
      fields: [
        {
          type: "select", component: "select", name: "status", label: "Statut du client", dataLabel: 'name', dataValue: 'id', default: defaults.status || 1, data: Object.values(window._DATA.types['client_status']).map((type) => {
            return { id: type.value, name: type.label }
          }),
        },
        { type: "text", component: "text", name: "username", label: "Nom d'utilisateur" },
        { type: "text", component: "text", name: "address", label: "Adresse" },
        { type: "text", component: "text", name: "cp", label: "Code postal" },
        { type: "text", component: "text", name: "city", label: "Ville" },
        { type: "text", component: "text", name: "country_code", label: "Pays", value: 'FR' },
        { type: "text", component: "text", name: "name", label: "Nom" },
        { type: "text", component: "text", name: "firstname", label: "Prénom" },
        { type: "email", component: "text", name: "email", label: "Email" },
        { type: "text", component: "text", name: "phone", label: "Téléphone" },
        { type: "radio", component: "switch", name: "review", label: "Avis laissé par le client", default: defaults.maintenance_enabled || 0 },
      ]
    }
  ]

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    <ArrowBack link="/gestion/clients" />
    <form className={classes.form} name="myForm">
      <Formfields classNameOuter={classes.clientType} field={{ type: "text", component: "switch", name: "type", label: "Client professionnel", default: defaults.hasOwnProperty('type') ? defaults.type : 1, handleChange: (e) => { setUserType(e) } }} />
      <div className={classes.container}>
        {formFields.map((element, index) => {
          if (element.hasOwnProperty('cond') && !element.cond) return true
          return <div key={index} className={classes.card}>
            {element.fields.map((el) => {
              if (el.hasOwnProperty('cond') && !el.cond) return true
              if (defaults[el.name] && !el.default) el.default = defaults[el.name]
              if (companyData[el.name]) el.default = companyData[el.name]

              // 
              // ─── DISPLAY FIELDS ───────────────────────────────────────
              //
              if (el.custom) {
                return el.component
              } else {
                return <Formfields key={el.name} field={el} slug={props.slug} />
              }
            })}
          </div>
        })}
      </div>
      <Button variant="contained" className={classes.submit} onClick={handleChange}>Enregistrer</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.filterDialog}
      >
        <DialogTitle id="alert-dialog-title">Complétons votre fiche client à l’aide des données INSEE</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form name="company_infos">
            <Formfields classNameOuter={classes.clientType} field={{ type: "text", component: "text", name: "client_siret", label: "Siret de votre client" }} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Revenir à la fiche client</Button>
          <Button onClick={getCompanyInfos} variant='contained' autoFocus>Rechercher</Button>
        </DialogActions>
      </Dialog>
    </form>
    <form name="appointment_form">
      <div className={classes.card + ' ' + classes.appointmentContainer}>
        <Formfields classNameOuter={classes.appointment} field={{ type: "text", component: "text", name: "name", label: "Sujet" }} />
        <Formfields classNameOuter={classes.appointment} field={{ type: "text", multiline: true, rows: 3, component: "text", name: "description", label: "Description" }} />
        <Formfields classNameOuter={classes.appointment} field={{
          type: "select", component: "select", name: "status", label: "Statut du rendez-vous", dataLabel: 'name', dataValue: 'id', default: 1, data: Object.values(window._DATA.types['appointment_status']).map((type) => {
            return { id: type.value, name: type.label }
          }),
        }} />
        <Formfields classNameOuter={classes.appointment} field={{ type: "datetime-local", component: "date", name: "time_start", label: "Date de début" }} />
        <Formfields classNameOuter={classes.appointment} field={{ type: "datetime-local", component: "date", name: "time_end", label: "Date de fin" }} />
      </div>
      <Button variant="contained" className={classes.submit} onClick={handleAppointment}>Créer un rendez-vous</Button>
    </form>
  </div >
};

export default ClientManage;