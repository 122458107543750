// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { Route, Routes } from 'react-router-dom';
import NotFound from '../views/NotFound';
import RequireAuth from "../components/RequireAuth";
import Layout from "../components/Layout";
import Cookies from 'universal-cookie';
import Login from "../views/Login";
import Home from '../views/Home';
import Analytics from '../views/Analytics';
import ClientAdd from "../views/Clients/ClientAdd";
import ClientEdit from "../views/Clients/ClientEdit";
import ClientsList from "../views/Clients/ClientsList";
import ClientView from "../views/Clients/ClientView";
import WebsiteAdd from "../views/Websites/WebsiteAdd";
import WebsiteEdit from "../views/Websites/WebsiteEdit";
import WebsitesList from "../views/Websites/WebsitesList";
import WebsiteCheckList from "../views/Websites/WebsiteCheckList";
import WebsiteDetail from "../views/Websites/WebsiteDetail";
import UserAdd from '../views/Users/UserAdd';
import UserEdit from '../views/Users/UserEdit';
import UsersList from "../views/Users/UsersList";
import ProjectsList from "../views/Projects/ProjectsList";
import ProjectAdd from "../views/Projects/ProjectAdd";
import ProjectEdit from "../views/Projects/ProjectEdit";
import ProjectDetail from "../views/Projects/ProjectsDetail";
import OfferAdd from "../views/Offers/OfferAdd";
import OfferEdit from "../views/Offers/OfferEdit";
import OfferList from "../views/Offers/OfferList";
import QuoteAdd from "../views/Quotes/QuoteAdd";
import QuoteEdit from "../views/Quotes/QuoteEdit";
import QuoteList from "../views/Quotes/QuoteList";
import TimeTrackingAdd from "../views/TimeTracking/TimeTrackingAdd";
import TimeTrackingEdit from "../views/TimeTracking/TimeTrackingEdit";
import TimeTrackingList from "../views/TimeTracking/TimeTrackingList";
import KilometricAllowanceAdd from "../views/KilometricAllowance/KilometricAllowanceAdd";
import KilometricAllowanceEdit from "../views/KilometricAllowance/KilometricAllowanceEdit";
import KilometricAllowanceList from "../views/KilometricAllowance/KilometricAllowanceList";
import Monitoring from "../views/Server/Monitoring";
import InventoryAdd from "../views/Inventory/InventoryAdd";
import InventoryEdit from "../views/Inventory/InventoryEdit";
import InventoryList from "../views/Inventory/InventoryList";
import Tools from "../views/Tools";
import LoremIpsum from '../containers/Tools/LoremIpsum';
import UuidGenerator from '../containers/Tools/UuidGenerator';
import Base64Converter from '../containers/Tools/Base64Converter';
import Bcrypt from '../containers/Tools/Bcrypt';
import QrCode from "../containers/Tools/QrCode";
import Changelog from "../views/Changelog";
import Deadlines from "../views/Ovh/Deadlines";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Router = () => {

  // Get active user infos
  const cookies = new Cookies();
  const userData = cookies.get('olwe_management_user') || {};

  // 
  // ─── DECLARATION OF ALL NECESSARY ROUTES ───────────────────────────────────────
  //
  const routes = [
    { path: '/', component: <Home /> },
    { path: '/dashboard/analytics', component: <Analytics /> },
    { path: '/gestion/clients', component: <ClientsList role={userData.role} /> },
    { path: '/gestion/clients/creation', component: <ClientAdd /> },
    { path: '/gestion/clients/:username/editer', component: <ClientEdit /> },
    { path: '/gestion/clients/:username/fiche', component: <ClientView />, role: 'super-admin' },
    { path: '/gestion/sites', component: <WebsitesList /> },
    { path: '/gestion/sites/nouveau', component: <WebsiteAdd /> },
    { path: '/gestion/sites/:domain', component: <WebsiteDetail role={userData.role} /> },
    { path: '/gestion/sites/:domain/editer', component: <WebsiteEdit /> },
    { path: '/gestion/sites/:domain/checklist', component: <WebsiteCheckList /> },
    { path: '/gestion/utilisateurs', component: <UsersList /> },
    { path: '/gestion/utilisateurs/nouveau', component: <UserAdd /> },
    { path: '/gestion/utilisateurs/:user_id/editer', component: <UserEdit /> },
    { path: '/gestion/projets', component: <ProjectsList />, role: 'super-admin' },
    { path: '/gestion/projets/nouveau', component: <ProjectAdd />, role: 'super-admin' },
    { path: '/gestion/projets/:project_id', component: <ProjectDetail role={userData.role} />, role: 'super-admin' },
    { path: '/gestion/projets/:project_id/editer', component: <ProjectEdit />, role: 'super-admin' },
    { path: '/gestion/offres', component: <OfferList />, role: 'super-admin' },
    { path: '/gestion/offres/nouveau', component: <OfferAdd />, role: 'super-admin' },
    { path: '/gestion/offres/:offer_id/editer', component: <OfferEdit />, role: 'super-admin' },
    { path: '/gestion/devis', component: <QuoteList />, role: 'super-admin' },
    { path: '/gestion/devis/nouveau', component: <QuoteAdd />, role: 'super-admin' },
    { path: '/gestion/offres/:quote_id/editer', component: <QuoteEdit />, role: 'super-admin' },
    { path: '/gestion/mes-temps', component: <TimeTrackingList role={userData.role} /> },
    { path: '/gestion/mes-temps/nouveau', component: <TimeTrackingAdd /> },
    { path: '/gestion/mes-temps/:time_tracking_id/editer', component: <TimeTrackingEdit /> },
    { path: '/gestion/indemnites-kilometriques', component: <KilometricAllowanceList role={userData.role} /> },
    { path: '/gestion/indemnites-kilometriques/nouvelle', component: <KilometricAllowanceAdd role={userData.role} /> },
    { path: '/gestion/indemnites-kilometriques/:kilometric_allowance_id/editer', component: <KilometricAllowanceEdit role={userData.role} /> },
    { path: '/outils/serveurs/monitoring', component: <Monitoring /> },
    { path: '/outils/inventaire', component: <InventoryList />, role: 'super-admin' },
    { path: '/outils/inventaire/nouveau', component: <InventoryAdd />, role: 'super-admin' },
    { path: '/outils/inventaire/:inventory_id/editer', component: <InventoryEdit />, role: 'super-admin' },
    { path: '/outils/tools', component: <Tools /> },
    { path: '/outils/tools/lorem-ipsum-generateur', component: <LoremIpsum /> },
    { path: '/outils/tools/uuid-generator', component: <UuidGenerator /> },
    { path: '/outils/tools/base64-string-converter', component: <Base64Converter /> },
    { path: '/outils/tools/bcrypt', component: <Bcrypt /> },
    { path: '/outils/tools/qrcode', component: <QrCode /> },
    { path: '/changelog', component: <Changelog /> },
    { path: '/ovh/echeances', component: <Deadlines />, role: 'super-admin' },
  ]

  // 
  // ─── ROUTE OVER ALL ROUTES AND CHECK FOR AUTH AND ROLES ───────────────────────────────────────
  //
  return <Routes>
    <Route path="/login" element={<Login />} />
    <Route element={<RequireAuth />}>
      <Route path="/" element={<Layout />}>
        {routes.map((route) => {
          if ((route.role && route.role.includes(userData.role)) || !route.hasOwnProperty('role')) {
            return <Route key={route.path} path={route.path} element={route.component} />
          }
        })}
      </Route>
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes >
};

export default Router