// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import Api from '../../contexts/Api';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { formatDate, formatTime, getValue } from '../../contexts/Utils';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Formfields from '../../components/Formfields';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const TimeTrackingList = (props) => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [timeTracking, setTimeTracking] = useState([]);
  const [filters, setFilters] = useState({})
  const isAdmin = props.role === 'super-admin';
  const cookies = new Cookies();
  const userData = cookies.get('olwe_management_user') || {};

  // 
  // ─── GET ALL TIMES TRACKING ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      let params = {
        endpoint: '/time_tracking',
        method: "GET"
      }

      if (!isAdmin) {
        params.query = { user: userData.user_id }
      }

      const getTimeTracking = await Api(params)
      if (getTimeTracking.success) {
        setTimeTracking(getTimeTracking.data)
      }
    }
    if (!timeTracking.length) {
      fetchData()
    }
  }, [isAdmin, timeTracking])

  // 
  // ─── CHECK IF DATE WAS TODAY OR YESTERDAY ───────────────────────────────────────
  //
  function checkDate(opt) {
    let checkDate = new Date();
    if (opt.yesterday) {
      checkDate.setDate(checkDate.getDate() - 1);
    }
    const d = new Date(opt.date)

    if (checkDate.toDateString() === d.toDateString()) {
      return true;
    }

    return false;
  }

  // 
  // ─── HANDLE FILTERS ───────────────────────────────────────
  //
  const handleUserFilter = (e) => {
    setFilters({ ...filters, ...{ user_id: e } })
  }

  const handleTimeFilter = (e) => {
    setFilters({ ...filters, ...{ time: e } })
  }

  // 
  // ─── GROUPE TIMES BY DATE ───────────────────────────────────────
  //
  let timeList = {}
  let timeStatsTotal = 0

  timeTracking.forEach((value) => {

    // Filter list by user
    if (filters.user_id && filters.user_id !== value.user_id) {
      return true;
    }

    // Filter list time
    if (filters.time) {
      let d = new Date();

      let actual_month = (d.getMonth() + 1).toString().padStart(2, 0);
      let actual_year = d.getFullYear();
      let month = formatDate({ time: value.date_time, display: 'month_number' })
      let year = formatDate({ time: value.date_time, display: 'year' })

      // Filter by month
      if (filters.time === 2 && actual_month !== month && year !== actual_year) {
        return true;
      }

    }

    const objectKey = formatDate({ time: value.date_time, display: 'date' })

    if (!timeList[objectKey]) {
      timeList[objectKey] = { duration: 0, times: [] }
    }
    timeList[objectKey].times.push(value);
    timeList[objectKey].duration += value.duration_seconds;
    timeList[objectKey].date_time = value.date_time;

    timeStatsTotal += value.duration_seconds;
  })

  // 
  // ─── SORT FORMATED VALUES ───────────────────────────────────────
  //
  timeList = Object.keys(timeList)
    .sort((a, b) => a.date_time - b.date_time)
    .reduce((accumulator, key) => {
      accumulator[key] = timeList[key];

      return accumulator;
    }, {});

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginBottom: 40,
      color: 'var(--text-color)'
    },
    timeTrackingHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px 20px',
      background: 'var(--background-color)',
    },
    title: {
      fontWeight: 'bold'
    },
    timeTrackingLineContainer: {
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      fontSize: 14,
      '&>div:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    },
    timeTrackingLine: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      gap: 20,
    },
    activity: {
      marginLeft: 'auto',
      background: 'var(--activity-color)',
      padding: '3px 6px',
      color: 'var(--activity-text-color)'
    },
    thumbnail: {
      width: 35,
      height: 35,
      borderRadius: '100%'
    },
    avatar: {
      width: '30px !important',
      height: '30px !important',
      fontSize: '12px !important',
      color: '#FFF !important',
      background: 'var(--avatar-color) !important'
    },
    projectContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    dot: {
      minWidth: 6,
      minHeight: 6,
      borderRadius: '100%',
      marginRight: 8
    },
    actionIconsContainer: {
      width: 20,
      height: 20,
      marginRight: 10,
      color: 'var(--text-color)',
      '&>svg': {
        width: '20px'
      }
    },
    filters: {
      display: 'flex',
      marginBottom: 40,
      gap: 10,
      '&>div': {
        width: '200px !important'
      }
    },
    userTotalHours: {
      display: 'block',
      fontWeight: 'bold',
      marginTop: -10,
      marginBottom: 30,
      color: 'var(--text-color)'
    }
  })
  const classes = useStyle();

  return <div>
    <div className={classes.filters}>
      <Formfields field={{
        type: "select", component: "select", name: "developer", label: "Collaborateur", dataLabel: 'name', dataValue: 'id', default: 0, data: [{ id: 0, name: 'Tous' }, ...window._DATA.users.map((value) => {
          return { id: value.user_id, name: `${value.firstname} ${value.name}` }
        })
        ], handleChange: handleUserFilter
      }} />
      <Formfields field={{
        type: "select", component: "select", name: "developer", label: "Période", dataLabel: 'name', dataValue: 'id', default: 0, data: [
          { id: 0, name: 'Tous' },
          // { id: 1, name: 'Semaine' },
          { id: 2, name: 'Mois' }
        ], handleChange: handleTimeFilter
      }} />
    </div>
    {Boolean(filters.user_id) && <span className={classes.userTotalHours}>{`Numbre total d'heure sur la période : ${formatTime(timeStatsTotal)}`}</span>}
    {Object.keys(timeList).map((title, index) => {
      const timeStart = timeList[title].date_time;
      const today = checkDate({ date: timeStart }) && "Aujourd'hui";
      const yesterday = checkDate({ date: timeStart, yesterday: true }) && "Hier";
      const formatedTitle = today || yesterday || title

      return <div className={classes.container} key={index}>
        <div className={classes.timeTrackingHeader}>
          <span className={classes.title}>{formatedTitle}</span>
          <span>{`Total: ${formatTime(timeList[title].duration)}`}</span>
        </div>
        <div className={classes.timeTrackingLineContainer}>
          {timeList[title].times.map((time_tracking, i) => {
            return <div className={classes.timeTrackingLine} key={i}>
              {isAdmin && <img className={classes.thumbnail} src={`/assets/img/team/${time_tracking.user_firstname.toLowerCase()}.jpg`} alt="" />}
              <span>{time_tracking.name}</span>
              {Boolean(time_tracking.project_name && (!time_tracking.holiday && !time_tracking.public_holiday)) && <div className={classes.projectContainer} style={{ color: '#FFB100' }}>
                <span className={classes.dot} style={{ background: '#FFB100' }} />
                <span>{`${time_tracking.project_name}`}</span>
              </div>}
              {Boolean(time_tracking.holiday) && <div className={classes.projectContainer} style={{ color: '#d40477' }}>
                <span className={classes.dot} style={{ background: '#d40477' }} />
                <span>Congés</span>
              </div>}
              {Boolean(time_tracking.public_holiday) && <div className={classes.projectContainer} style={{ color: '#d40477' }}>
                <span className={classes.dot} style={{ background: '#d40477' }} />
                <span>Férié</span>
              </div>}
              <span className={classes.activity}>{getValue(window, ['_DATA', 'types', 'task_activity', time_tracking.activity, 'label'])}</span>
              <Tooltip title={`${time_tracking.billable ? 'Facturable' : 'Non facturable'}`}>
                <span style={{ color: time_tracking.billable ? '#FFB100' : '' }}>€</span>
              </Tooltip>
              {Boolean(time_tracking.all_day) && <span>Toute la journée</span>}
              <span>{time_tracking.duration}</span>
              <Link
                to={`/gestion/mes-temps/${time_tracking.time_tracking_id}/editer`}
                className={classes.actionIconsContainer}
              >
                <EditIcon />
              </Link>
            </div>
          })}
        </div>
      </div>
    })}
  </div >
};

export default TimeTrackingList;