// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Api from '../contexts/Api';
import Formfields from "../components/Formfields";
import { prepareFormData, getValue } from '../contexts/Utils';
import Button from '@mui/material/Button';
import { useNotification } from '../components/UseNotification';
import ArrowBack from '../components/ArrowBack';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const OfferManage = (props) => {
  const { notification } = useNotification()
  let navigate = useNavigate();
  let defaults = props.defaults || {}

  const [errors, setErrors] = useState({});

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'myForm' });

    if (data.errors) {
      setErrors(data.errors)
    } else {

      if (props.update) {
        data.offer_id = defaults.offer_id
      }

      const params = {
        data,
        endpoint: '/offers',
        method: props.update ? 'PUT' : 'POST'
      }

      const createOffers = await Api(params)
      notification({ variant: createOffers.success ? 'success' : 'error', message: createOffers.message })

      if (createOffers.success) {
        return navigate("/gestion/offres");
      }
    }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      gap: 20
    },
    card: {
      flex: 1,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 20,
      borderRadius: 20,
      '&>div:not(:first-child)': {
        marginTop: 20
      }
    },
    submit: {
      margin: '20px auto 10px !important'
    }
  })
  const classes = useStyle();

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    {
      fields: [
        { component: "text", name: "reference", label: "Référence" },
        { component: "text", name: "name", label: "Nom" },
        { component: "text", multiline: true, rows: 3, name: "description", label: "Description" },
        { component: "text", name: "price", label: "Prix" },
        { component: "text", name: "tva", label: "TVA %" },
      ]
    }
  ]

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <div>
    <ArrowBack link='/gestion/offres' />
    <form onSubmit={handleChange} className={classes.form} name="myForm">
      <div className={classes.container}>
        {formFields.map((element, index) => {
          return <div key={index} className={classes.card}>
            {element.fields.map((el) => {
              if (el.hasOwnProperty('cond') && !el.cond) return true
              if (defaults[el.name] && !el.default) el.default = defaults[el.name]

              // 
              // ─── DISPLAY FIELDS ───────────────────────────────────────
              //
              return <Formfields key={el.name} field={{ ...el, error: getValue(errors, [el.name, 'message']) }} slug={props.slug} />
            })}
          </div>
        })}
      </div>
      <Button variant="contained" className={classes.submit} onClick={handleChange}>Enregistrer</Button>
    </form>
  </div >
};

export default OfferManage;