import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import './assets/css/main.scss';
import './assets/css/backoffice.scss';

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Router>
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();