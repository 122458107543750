import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

const LineChart = (props) => {

  let chartData = [];
  let values = props.data;

  const state = {
    labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    datasets: [
      {
        label: 'Ventes',
        fill: true,
        lineTension: 0.5,
        backgroundColor: 'rgba(255, 177, 0,0.2)',
        borderColor: 'rgb(255, 177, 0)',
        borderWidth: 3,
        data: [null, 65, 59, 80, 81, 56, null, null]
      }
    ]
  }

  state.labels.map((date) => {
    chartData.push(values[date] ? values[date] : null)
  })

  state.datasets[0].data = chartData;

  return (
    <div>
      <Line
        data={state}
        options={{
          title: {
            display: true,
            text: 'Average Rainfall per month',
            fontSize: 20
          },
          legend: {
            display: true,
          }
        }}
      />
    </div>
  );
}

export default LineChart;