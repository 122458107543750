// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import WebsiteManage from '../../containers/WebsiteManage'
import Api from '../../contexts/Api'

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const WebsiteEdit = () => {
  const websiteParams = useParams()

  const [website, setWebsite] = useState()

  // 
  // ─── GET WEBSITE TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchWebsite = async () => {

      const getWebsites = await Api({
        endpoint: '/websites',
        query: {
          domain: websiteParams.domain
        },
        method: "GET"
      })

      if (getWebsites.success) {
        setWebsite(getWebsites.content)
      }
    }
    if (!website) {
      fetchWebsite()
    }

  }, [website, websiteParams])

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <>
    {website && <WebsiteManage defaults={website} update={true} />}
  </>
};

export default WebsiteEdit;