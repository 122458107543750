// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ArrowBack = (props) => {
  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      marginBottom: 20,
      color: 'var(--text-color)',
      textDecoration: 'none',
      '&>svg': {
        transition: '0.2s'
      },
      '&:hover': {
        '&>svg': {
          transform: 'translateX(-1.5px)'
        }
      }
    }
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Link to={`${props.link}`} className={classes.container}>
    <KeyboardBackspaceIcon />
    <span>retour</span>
  </Link>
};

export default ArrowBack;