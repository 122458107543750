// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React from 'react';
import WebsiteManage from '../../containers/WebsiteManage'

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const WebsiteAdd = () => {
  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <>
    <WebsiteManage />
  </>
};

export default WebsiteAdd;