import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
// 
// ─── IMPORT ICONES ───────────────────────────────────────
//

const ThemeToggle = (props) => {
  // 
  // ─── RÉCUPÉRATION DU STATE ───────────────────────────────────────
  //
  const [theme, setTheme] = useState(localStorage.getItem("olwe_theme") || 'light');

  // 
  // ─── UPDATE DU THEME LORS DU CLIQUE ───────────────────────────────────────
  //
  const handleChange = (data) => {
    localStorage.setItem("olwe_theme", data);
    setTheme(data);
    props.handleChange(data)
  }

  // 
  // ─── MISE A JOUR DU DATA SUR LA BALSIE HTML ───────────────────────────────────────
  //
  useEffect(() => {
    document.querySelector('html').setAttribute('data-theme', localStorage.getItem('olwe_theme') || "light")
  }, [theme]);

  // 
  // ───  DEFINITION DES STYLES DU COMPOSANT ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    themeIcon: {
      position: 'absolute !important',
      top: 20,
      right: 20,
    }
  })

  // 
  // ─── RECUPERATION DES STYLES ───────────────────────────────────────
  //
  const classes = useStyle()

  return <IconButton aria-label="delete" className={classes.themeIcon} onClick={() => handleChange(theme === "dark" ? "light" : "dark")}>
    {theme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
  </IconButton>

  // return <button onClick={() => handleChange(theme === "dark" ? "light" : "dark")} className={props.classNameOuter + ' ' + classes.themeIcon}>theme</button>
};

export default ThemeToggle;