// 
// ─── IMPORT DES ELEMENTS NECESSAIRESS ───────────────────────────────────────
//
import React from 'react';
import TimeTrackingManage from '../../containers/TimeTrackingManage';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const TimeTrackingAdd = () => {
  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <TimeTrackingManage />
};

export default TimeTrackingAdd;