// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss'
import Button from '@mui/material/Button';
import Api from '../../contexts/Api';
import Lighthouse from '../../assets/img/lighthouse-google.png'
import { formatTime } from '../../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const WebsiteDetail = (props) => {
  const { domain } = useParams();

  const [website, setWebsite] = useState();
  const [loading, setLoading] = useState(false);

  // 
  // ─── GET WEBSITE TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchWebsite = async () => {

      const getWebsites = await Api({
        endpoint: '/websites',
        query: {
          domain: domain,
          time_tracking: true,
          detail: true
        },
        method: "GET"
      })
      if (getWebsites.success) {
        setWebsite(getWebsites.content)
      }
    }
    if (!website) {
      fetchWebsite()
    }

  }, [website, domain])

  const generateLightHouse = async () => {

    const params = {
      data: {
        domain
      },
      endpoint: '/websites/lighthouse',
    }

    const getLighthouse = await Api(params);
    setWebsite(getLighthouse.content, setLoading(false))
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    title: {
      color: 'var(--text-color)'
    },
    subtitle: {
      color: 'var(--text-color)'
    },
    card: {
      padding: 20,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
    },
    lighthouseContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 650,
      alignItems: 'center',
    },
    lighthouseImage: {
      width: 200
    },
    lighthouseScoreContainer: {
      display: "flex",
      justifyContent: 'space-between',
      width: '100%',
      margin: '30px 0'
    },
    lighthouseScore: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 10,
      width: 120
    },
    donut: {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center',
      width: 60,
      height: 60,
      borderRadius: '50%',
      position: 'relative'
    },
    scoreTitle: {
      color: 'var(--text-color)'
    },
    hole: {
      width: 50,
      height: 50,
      borderRadius: '50%',
      background: '#fff',
    },
    score: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: 'bold'
    },
    timeTrackingContainer: {
      display: 'flex',
      gap: 20,
      alignItems: 'center',
      color: 'var(--text-color)'
    },
    timeTrackingUser: {
      fontWeight: 'bold',
      fontSize: 20
    },
    timeTrackingWrapper: {
      display: 'flex',
      gap: 20,
      flexWrap: 'wrap'
    },
    timeTrackingContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },
    thumbnail: {
      width: 50
    }
  })

  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return website && <div>
    <h1 className={classes.title}>Détail d'un site</h1>
    <h2 className={classes.subtitle}>Lighthouse</h2>
    <div className={classes.lighthouseContainer + ' ' + classes.card}>
      <img src={Lighthouse} alt="Google Lighthouse" className={classes.lighthouseImage} />
      <div className={classes.lighthouseScoreContainer}>
        {website.lighthouse && Object.entries(website.lighthouse).map(([key, values]) => {
          let score = values.score * 100;
          let degree = score * 3.6;
          let background;
          let scoreColor = '#0c6';
          let scoreBackground = '#eafaf1';

          if (score < 85) {
            scoreColor = '#ffaa33';
            scoreBackground = '#fff7ed';
          }
          if (score < 45) {
            scoreColor = '#ee4141';
            scoreBackground = '#ffe4e4';
          }

          if (score === 100) {
            background = `conic-gradient(${scoreColor} 0deg 360deg)`;
          } else {
            background = `conic-gradient(${scoreColor} 0deg ${degree}deg,transparent ${degree}deg 360deg)`;
          }

          return <div className={classes.lighthouseScore}>
            <span className={classes.scoreTitle}>{values.title}</span>
            <div className={classes.donut} style={{ background }}>
              <div className={classes.hole} style={{ background: scoreBackground }}></div>
              <span className={classes.score} style={{ color: scoreColor }}>{Math.round(score)}</span>
            </div>
          </div>
        })}
      </div>
      {loading && <span>Loading</span>}
      <Button className={classes.submit} onClick={() => setLoading(true, generateLightHouse())} variant='contained' >Générer le lighthouse</Button>
    </div>
    {
      props.role === 'super-admin' && website.time_tracking && <>
        <h2 className={classes.subtitle}>Time Tracking</h2>
        <div className={classes.timeTrackingWrapper}>
          {Object.entries(website.time_tracking).map(([key, value]) => {
            let user = window._DATA.users.filter(user => user.user_id === key)
            return <div className={classes.card + ' ' + classes.timeTrackingContainer}>
              <img className={classes.thumbnail} src={`/assets/img/team/${user[0].firstname.toLowerCase()}.jpg`} alt="" />
              <div className={classes.timeTrackingContent}>
                <span className={classes.timeTrackingUser}>{user[0].firstname}</span>
                <span>{formatTime(value.duration)}</span>
              </div>
            </div>
          })}
        </div>
      </>
    }
  </div >
};

export default WebsiteDetail;