// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import { useNavigate, Navigate } from 'react-router-dom';
import Api from '../contexts/Api';
import { prepareFormData } from '../contexts/Utils';
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';
import Cookies from 'universal-cookie';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Login = () => {

  let navigate = useNavigate();
  const { notification } = useNotification()
  const cookies = new Cookies();
  const userData = cookies.get('olwe_management_user');

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: "email", component: "text", name: "email", label: "Email" },
    { type: "password", component: "text", name: "password", label: "Mot de passe" },
  ]

  // 
  // ─── HANDLE USER LOGIN ───────────────────────────────────────
  //
  const handleChange = async () => {
    const data = prepareFormData({ formId: 'loginForm' });

    const params = {
      data,
      endpoint: '/auth/login'
    }
    const logUser = await Api(params)

    // 
    // ─── ON SUCCESS WE USE REDUX TO LOG USER ───────────────────────────────────────
    //
    if (logUser.success && logUser.access_token) {
      cookies.set('olwe_management_user', logUser.data, {
        expires: new Date(Date.now() + 604800000), // time until expiration
        secure: false, // true if using https
      })
      return navigate("/");
    } else {
      notification({ variant: 'error', message: logUser.message })
    }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      minHeight: '100vh',
      padding: '0 20px',
      margin: 'auto',
    },
    logo: {
      width: 100,
    },
    form: {
      maxWidth: 500,
      margin: "auto",
      background: "var(--block-color)",
      padding: 20,
      boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20
    },
    title: {
      margin: 0,
      color: 'var(--text-color)',
      fontSize: '2em'
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%'
    },
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return userData ? <Navigate to="/" /> : <div className={classes.container}>
    <form className={classes.form} name='loginForm'>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>Connexion</h1>
        <svg className={classes.logo} viewBox="0 0 694 471" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path d="M471.636 223H593.604C598.224 223 600.996 220.228 600.996 215.608V189.12C600.996 184.5 598.224 181.728 593.604 181.728H509.52V30.192C509.52 25.572 506.748 22.8 502.128 22.8H471.636C467.016 22.8 464.244 25.572 464.244 30.192V215.608C464.244 220.228 467.016 223 471.636 223Z" fill="var(--text-color)" />
            <path d="M549.636 449H672.22C676.84 449 679.612 446.228 679.612 441.608V415.12C679.612 410.5 676.84 407.728 672.22 407.728H587.52V369.536H665.752C670.372 369.536 673.144 366.764 673.144 362.144V335.656C673.144 331.036 670.372 328.264 665.752 328.264H587.52V290.072H672.22C676.84 290.072 679.612 287.3 679.612 282.68V256.192C679.612 251.572 676.84 248.8 672.22 248.8H549.636C545.016 248.8 542.244 251.572 542.244 256.192V441.608C542.244 446.228 545.016 449 549.636 449Z" fill="var(--text-color)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 123C12 178.228 56.7715 223 112 223C113.618 223 115.228 222.962 116.828 222.886C117.207 222.961 117.599 223 118 223L206 223C209.314 223 212 220.314 212 217L212 129C212 128.599 211.961 128.207 211.886 127.828C211.962 126.228 212 124.618 212 123C212 67.7715 167.228 23 112 23C56.7715 23 12 67.7715 12 123Z" fill="#FFB100" fillOpacity="0.7" />
            <path fillRule="evenodd" clipRule="evenodd" d="M112 449C167.228 449 212 404.228 212 349C212 347.382 211.962 345.772 211.886 344.172C211.961 343.793 212 343.401 212 343V255C212 251.686 209.314 249 206 249L118 249C117.599 249 117.207 249.039 116.828 249.114C115.228 249.038 113.618 249 112 249C56.7715 249 12 293.772 12 349C12 404.228 56.7715 449 112 449Z" fill="#F4D180" fillOpacity="0.4" />
            <path fillRule="evenodd" clipRule="evenodd" d="M338 223C393.228 223 438 178.228 438 123C438 67.7715 393.228 23 338 23C282.772 23 238 67.7715 238 123C238 124.618 238.038 126.228 238.114 127.828C238.039 128.207 238 128.599 238 129L238 217C238 220.314 240.686 223 244 223H332C332.401 223 332.793 222.961 333.172 222.886C334.772 222.962 336.382 223 338 223ZM338 178C368.376 178 393 153.376 393 123C393 92.6243 368.376 68 338 68C307.624 68 283 92.6243 283 123C283 153.376 307.624 178 338 178Z" fill="#FFB100" />
            <path d="M309.308 449H335.18C339.492 449 342.572 446.844 343.804 442.84L377.068 342.432L410.332 442.84C411.564 446.844 414.644 449 418.956 449H444.828C449.14 449 452.22 446.844 453.452 442.84L515.36 257.116C516.9 252.188 514.436 248.8 509.2 248.8H476.552C472.24 248.8 469.16 250.956 467.928 254.96L432.816 360.912L397.088 254.96C395.856 250.956 392.776 248.8 388.464 248.8H368.136C363.824 248.8 361.052 250.956 359.82 254.96L322.86 366.764L285.9 254.96C284.668 250.956 281.896 248.8 277.584 248.8H244.936C239.7 248.8 237.236 252.188 238.776 257.116L300.684 442.84C301.916 446.844 304.996 449 309.308 449Z" fill="var(--text-color)" />
          </g>
        </svg>
      </div>
      {formFields.map((el) => (<Formfields key={el.name} field={el} />))}
      <div className={classes.btnContainer}>
        <Button variant="contained" onClick={handleChange} autoFocus>Connexion</Button>
      </div>
    </form>
  </div>
};

export default Login;