// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Api from '../contexts/Api';
import Formfields from "../components/Formfields"
import { getValue, prepareFormData } from '../contexts/Utils';
import { useNotification } from '../components/UseNotification';
import ArrowBack from '../components/ArrowBack';

const WebsiteManage = (props) => {
  const { notification } = useNotification();
  let navigate = useNavigate();

  // 
  // ─── DEFINITION DES STATES ───────────────────────────────────────
  //
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);

  // 
  // ─── TRAITEMENT DU FORMULAIRE + CALL API ───────────────────────────────────────
  //
  const handleChange = async (event) => {
    event.preventDefault();

    let data = prepareFormData({ formId: 'myForm', multiple: 'colors' })

    const params = {
      data,
      endpoint: '/websites',
      method: props.update ? 'PUT' : 'POST'
    }

    const createWebsite = await Api(params)
    notification({ variant: createWebsite.success ? 'success' : 'error', message: createWebsite.message })

    if (createWebsite.success) {
      return navigate("/gestion/sites");
    }
  }

  // 
  // ─── RÉCPÉRATION DES USERNAME/ID DES CLIENTS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/clients',
        query: {
          fields: 'username,client_id'
        },
        method: "GET"
      }
      const getClients = await Api(params)
      if (getClients.success) {
        setClients(getClients.data)
      }
    }
    if (!clients.length) { fetchData() }

  }, [clients])

  // 
  // ─── GET ALL PROJECTS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/projects',
        query: {
          fields: 'projects.name,project_id'
        },
        method: "GET"
      }
      const getProjects = await Api(params)
      if (getProjects.success) {
        setProjects(getProjects.data)
      }
    }
    if (!projects.length) { fetchData() }

  }, [projects])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      gap: 20
    },
    card: {
      flex: 1,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 20,
      borderRadius: 20,
      '&>div:not(:first-child)': {
        marginTop: 20
      }
    },
    submit: {
      margin: '20px auto 10px !important'
    }
  })
  const classes = useStyle();

  // 
  // ─── RECUPERATION DE L'ID DU CLIENT GRACE AU USERNAME ───────────────────────────────────────
  //
  let defaults = props.defaults || {}
  if (defaults.username) {
    const defaultClient = clients.filter(v => v.username === defaults.username);
    if (defaultClient.length) {
      defaults['client_id'] = getValue(defaultClient, [0, 'client_id'])
    }
  }

  if (!defaults.colors && !props.update) {
    defaults.colors = '{ "0": { "label": "--primary-color", "value": "" }, "1": { "label": "--secondary-color", "value": "" }, "3": { "label": "--cookie", "value": "" }, "4": { "label": "--cookie-hover", "value": "" }, "5": { "label": "--cookie-text-color", "value": "" }, "6": { "label": "--not-found", "value": "" } }'
  }

  // 
  // ─── CHAMPS DU FORMULAIRE ───────────────────────────────────────
  //
  const formFields = [
    {
      fields: [
        { type: "select", component: "select", name: "client_id", label: "Username du client", data: [], default: defaults.client_id || '-', dataLabel: 'username', dataValue: "client_id" },
        { type: "text", component: "text", name: "domain", label: "Nom de domaine", default: defaults.domain },
        { type: "select", component: "select", name: "project_id", label: "Projet", dataLabel: 'name', dataValue: 'project_id', default: defaults.project_id || '', data: projects },
        {
          type: "radio", component: "radio", name: "host", label: "Type d'hébergement", default: defaults.host, value: [
            { label: 'Interne', value: 1 },
            { label: 'Externe', value: 2 }
          ]
        },
        { type: "text", component: "text", name: "google_analytics_id", label: "ID analytics", default: defaults.google_analytics_id },
        { type: "text", component: "text", name: "facebook_pixel_id", label: "ID du pixel facebook", default: defaults.facebook_pixel_id },
        { type: "radio", component: "switch", name: "online", label: "En ligne", default: defaults.online, value: 1 },
        {
          type: "select", component: "select", name: "developer", label: "Développeur", dataLabel: 'name', dataValue: 'id', default: defaults.developer, data: window._DATA.users.map(element => (
            { id: element.user_id, name: element.firstname }
          ))
        },
        { type: "datetime-local", component: "text", name: "time_online", label: "Date de mise en ligne", cond: !props.update },
      ]
    }, {
      fields: [
        { type: "text", component: "multiple", components: { value: 'color' }, name: "colors", default: defaults.colors, label: "Couleurs du site", multiline: true, titles: { label: "Nom de la couleur", value: "Contenu" } },
        { component: "search", multiple: true, data: JSON.parse(localStorage.getItem("olwe_google_fonts")) || [], default: defaults.fonts, name: "fonts", label: "Polices" },
      ]
    }
  ]

  return <div>
    <ArrowBack link='/gestion/sites' />
    <form onSubmit={handleChange} className="form" name="myForm">
      <div className={classes.container}>
        {formFields.map((element, index) => {
          return <div key={index} className={classes.card}>
            {element.fields.map((el) => {
              if (el.name === 'client_id') {
                el.data = clients
              }

              if (el.hasOwnProperty('cond') && !el.cond) return true

              // 
              // ─── AFFICHAGE DES CHAMPS ───────────────────────────────────────
              //
              return <Formfields key={el.name} field={el} slug={props.slug} />
            })}
          </div>
        })}
      </div>
      <Button variant="contained" className={classes.submit} onClick={handleChange}>Enregistrer</Button>
    </form>
  </div >
};

export default WebsiteManage;