import React, { useState } from 'react';
import { createUseStyles } from 'react-jss'

// 
// ───  IMPORT DES ELEMENTS REACT MATERIAL UI ───────────────────────────────────────
//
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

const TableList = (props) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialog, setDialog] = useState({ open: false });
  const actions = props.actions;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // 
  // ─── HANDLE DIALOG ACTION EVENTS ───────────────────────────────────────
  //
  const handleDialogActions = (opt) => {
    setDialog({
      open: true,
      actions: {
        cancel: () => { setDialog({ ...dialog, open: false }) },
        validate: () => {
          actions.delete({ id: opt.delete })
          setDialog({ ...dialog, open: false })
        },
      }
    })
  }

  // 
  // ───  DEFINITION DES STYLES DU COMPOSANT ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      borderRadius: "1rem !important",
      boxShadow: "0 20px 27px 0 rgba(0, 0, 0, 0.05) !important",
      backgroundColor: 'var(--block-color) !important'
    },
    edit: {
      marginRight: 10
    },
    icon: {
      cursor: "pointer"
    },
    dialogContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    title: {
      fontWeight: 'bold',
      color: 'var(--secondary-color)',
      padding: "20px 0 !important"
    },
    description: {
      paddingRight: 20
    },
    action: {
      cursor: 'pointer'
    }
  })

  const classes = useStyle()
  let data = props.pagination && props.data.table_content.length ? { ...props.data, table_content: props.data.table_content.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) } : props.data

  return <>
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.header.map((col) => (
              <TableCell key={col} align="left">{col}</TableCell>
            ))}
            {props.actions && <TableCell align="left">ACTION</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.table_content.map((row, index) => {
            // Gérer l'index en fonction des pages et du nombre d'éléments par page
            const setIndex = (page * rowsPerPage) + index;

            return <TableRow key={index}>
              {Object.values(row).map((col, index) => <TableCell key={index} className={classes.row} align="left">{col || col === 0 ? col : '-'}</TableCell>)}
              {actions && <TableCell key={index} className={classes.row} align="left">
                {Boolean(actions.view) && props.role === 'super-admin' && <Tooltip title="Aperçu">
                  <span className={classes.action} onClick={() => actions.view({ name: data.table_name[setIndex], id: data.table_id[setIndex] })}>
                    {/* <FontAwesomeIcon icon={faSearch} className={classes.edit + ' ' + classes.icon} /> */}
                    <SearchIcon />
                  </span>
                </Tooltip>}
                {<Tooltip title="Éditer">
                  <span className={classes.action} onClick={() => actions.edit({ name: data.table_name[setIndex], id: data.table_id[setIndex], data: data.default[setIndex] })}>
                    <EditIcon />
                    {/* <FontAwesomeIcon icon={faPencil} className={classes.edit + ' ' + classes.icon} /> */}
                  </span>
                </Tooltip>}
                {Boolean(actions.delete) && <Tooltip title="Supprimer">
                  <span className={classes.action} onClick={() => {
                    handleDialogActions({ delete: data.table_id[setIndex] })
                  }}>
                    <DeleteIcon />
                  </span>
                </Tooltip>}
              </TableCell>}
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    {
      props.pagination && <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.data.table_content.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage='Lignes par page'
        labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' sur ' + count
          }
        }
      />
    }
  </>
};

export default TableList;