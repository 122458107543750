import { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Api from '../contexts/Api';
import Cookies from 'universal-cookie';

const PersistLogin = () => {
  const [authenticated, setAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true)
  const cookies = new Cookies();
  const userData = cookies.get('olwe_management_user') || {};

  // 
  // ─── MANAGE APP CONNECTION ───────────────────────────────────────
  //
  useEffect(() => {

    // 
    // ─── CHECK AUTHENTICATION ───────────────────────────────────────
    //
    const authenticateUser = async () => {
      const params = { endpoint: '/auth/authenticate' }

      const isAuthenticated = await Api(params)

      if (isAuthenticated && isAuthenticated.success) {
        Promise.all([
          !localStorage.getItem("olwe_google_fonts") && Api({ endpoint: `/external_api/google/fonts`, method: 'GET' }),
          Api({ endpoint: `/data`, method: 'GET' }),
          Api({ endpoint: `/users`, method: 'GET' })
        ])
          .then(([fonts, app_data, users]) => {
            if (!localStorage.getItem("olwe_google_fonts")) {
              localStorage.setItem('olwe_google_fonts', JSON.stringify(fonts.data))
            }
            window._DATA = {};
            window._DATA.types = app_data.data;
            window._DATA.users = users.data

            setAuthenticated(true)
            setLoading(false);
          });
      } else {
        setAuthenticated(false)
        cookies.remove('olwe_management_user')
        setLoading(false);
      }
    }

    // 
    // ─── IF USER IS LOGGED CHECK AUTHENTIFICATION ───────────────────────────────────────
    //
    if (Object.keys(userData).length && authenticated == null) {
      authenticateUser()
    } else if (authenticated == null) {
      setAuthenticated(false)
      setLoading(false)
    }
  }, [authenticated, userData])

  return authenticated && !loading ? <Outlet /> : loading ? <div>Loading</div> : <Navigate to="/login" />
}

export default PersistLogin