// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserManage from '../../containers/UserManage'
import Api from '../../contexts/Api'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const UserEdit = () => {
  const { user_id } = useParams();
  const usersInfos = window._DATA.users.filter(user => user.user_id === user_id)
  const { name, firstname } = usersInfos[0];
  const [user, setClient] = useState()

  // 
  // ─── GET USER TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/users',
        query: {
          name: name,
          firstname: firstname
        },
        method: "GET"
      }

      const getClients = await Api(params)
      if (getClients.success) {
        setClient(getClients.data[0])
      }
    }
    if (!user) {
      fetchData()
    }
  }, [firstname, name, user, usersInfos])

  return <>
    {user && <UserManage defaults={user} update={true} />}
  </>
};

export default UserEdit;