// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
var bcrypt = require('bcryptjs');

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Bcrypt = () => {

  const [bcryptSalt, setBcryptSalt] = useState(10)
  const [bcryptPassword, setBcryptPassword] = useState("");

  var salt = bcrypt.genSaltSync(bcryptSalt);
  var hash = bcrypt.hashSync(bcryptPassword, salt);

  // 
  // ─── SET PASSWORD TO BCRYPT ───────────────────────────────────────
  //
  const hashPassword = (e) => {
    setBcryptPassword(e.target.value);
  }

  // 
  // ─── COMPARE STRING PASSWORD AND BCRYPT HASH ───────────────────────────────────────
  //
  const comparePassword = () => {
    const comparePassword = document.querySelector('.comparePassword').value;
    const compareHash = document.querySelector('.compareHash').value;
    const compareResult = document.querySelector('.compareResult');

    if (bcrypt.compareSync(comparePassword, compareHash)) {
      compareResult.innerHTML = 'Oui'
      compareResult.style.color = 'green';
    } else {
      compareResult.innerHTML = 'Non'
      compareResult.style.color = 'red';
    }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      color: 'var(--text-color)',
      maxWidth: 900,
      margin: 'auto',
    },
    container: {
      display: 'flex',
      gap: 20
    },
    block: {
      width: '50%',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      borderColor: 'transparent',
      padding: 20,
      background: 'var(--block-color)',
    },
    textarea: {
      width: 'calc(100% - 20px)',
      padding: '5px 10px',
      color: 'var(--text-color)',
      outline: 'none',
      fontFamily: 'Open-sans, sans-serif',
      lineHeight: 1.5,
      background: 'var(--block-color)',
      border: '1px solid var(--colored-element-color)',
      '&:focus': {
        border: '1px solid var(--colored-element-color)',
        background: 'rgba(255, 177, 0,0.2)'
      }
    },
    formContainer: {
      '&:nth-child(2)': {
        marginBottom: 10,
      },
      "&>span": {
        display: 'block',
        marginBottom: 10
      }
    },
    formTitle: {
      marginTop: 0
    },
    bcryptfield: {
      marginTop: 20
    },
    compareResult: {
      marginTop: 10
    }
  })

  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    <div className='tools-header'>
      <h1>Base64 string encoder/decoder</h1>
      <p>Il suffit de coder et de décoder une chaîne de caractères dans sa représentation base64.</p>
    </div>
    <div className={classes.container}>
      <div className={classes.block}>
        <h2 className={classes.formTitle}>Hash</h2>
        <div className={classes.formContainer}>
          <span>Votre mot de passe</span>
          <textarea className={classes.textarea} rows='1' placeholder="Mot de passe à bcrypt..." onChange={hashPassword} />
        </div>
        <div className={classes.formContainer}>
          <span>Salt</span>
          <textarea className={classes.textarea} rows='1' value={bcryptSalt} onChange={(e) => { setBcryptSalt(e.target.value) }} />
        </div>
        <textarea className={classes.textarea + ' ' + classes.bcryptfield} rows='2' value={hash} readOnly></textarea>
      </div>
      <div className={classes.block}>
        <h2 className={classes.formTitle}>Comparer les éléments</h2>
        <div className={classes.formContainer}>
          <span>Mot de passe</span>
          <textarea className={classes.textarea + ' comparePassword'} rows='1' placeholder='Mot de passe à comparer...' onChange={comparePassword} />
        </div>
        <div className={classes.formContainer}>
          <span>Hash</span>
          <textarea className={classes.textarea + ' compareHash'} rows='2' placeholder="Hash à comparer..." onChange={comparePassword} />
        </div>
        <div className={classes.compareResult}>Est-ce qu'ils coincident ? <span className='compareResult' style={{ color: 'red' }}>Non</span></div>
      </div>
    </div>
  </div >
};

export default Bcrypt;