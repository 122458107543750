// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import KilometricAllowanceManage from '../../containers/KilometricAllowanceManage';
import Api from '../../contexts/Api'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const KilometricAllowanceEdit = () => {
  const kilometricParams = useParams();
  const [kilometricAllowance, setKilometricAllowance] = useState();

  // 
  // ─── GET OFFERS ELEMENT TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/kilometric_allowance',
        query: {
          kilometric_allowance_id: kilometricParams.kilometric_allowance_id
        },
        method: "GET"
      }

      const getInventory = await Api(params)
      if (getInventory.success) {
        setKilometricAllowance(getInventory.data)
      }
    }
    if (!kilometricAllowance) {
      fetchData()
    }
  }, [kilometricAllowance, kilometricParams])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    {kilometricAllowance && <KilometricAllowanceManage defaults={kilometricAllowance} update={true} />}
  </>
};

export default KilometricAllowanceEdit;