// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../../contexts/Api';
import TableList from '../../components/TableList';
import { getValue } from '../../contexts/Utils';


// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const InventoryList = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [inventory, setInventory] = useState()
  let navigate = useNavigate();

  // 
  // ─── GET ALL INVENTORY ELEMENT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/inventory',
        method: "GET"
      }

      const getInventory = await Api(params)
      if (getInventory.success) {
        setInventory(getInventory.data)
      }
    }
    if (!inventory) {
      fetchData()
    }
  }, [inventory])


  let formatInventory = {
    default: [],
    table_content: [],
    table_id: [],
    table_name: []
  }

  inventory && inventory.length && inventory.forEach((value) => {
    formatInventory.table_content.push({
      category: getValue(window, ['_DATA', 'types', 'inventory_category', value.category, 'label']),
      name: value.name,
      brand: value.brand,
      vendor: value.vendor,
      quantity: value.quantity,
      price: value.price + ' €',
    })
    formatInventory.table_id.push(value.inventory_id)
    formatInventory.table_name.push(value.inventory_id)
  })

  // 
  // ─── ADD ACTIONS TO THE TABLELIST ───────────────────────────────────────
  //
  const actions = {
    edit: (opt) => { navigate(`${opt.name}/editer`) }
  }

  return <div>
    <TableList data={formatInventory} header={['CATÉGORIE', 'NOM', 'MARQUE', 'VENDEUR', 'QUANTITÉ', 'PRIX']} actions={actions} pagination={10} type='inventory' />
  </div >
};

export default InventoryList;