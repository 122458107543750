// 
// ─── IMPORT DES ELEMENTS NECESSAIRESS ───────────────────────────────────────
//
import React from 'react';
import ProjectManage from '../../containers/ProjectManage';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const AddProject = () => {
  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <ProjectManage />
};

export default AddProject;