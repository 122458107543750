// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Api from '../../contexts/Api';
import { formatDate } from '../../contexts/Utils';
import Formfields from '../../components/Formfields';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const KilometricAllowanceList = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [kilometricAllowance, setKilometricAllowance] = useState([]);
  const [filters, setFilters] = useState({});

  // 
  // ─── GET ALL KILOMETRIC ALLOWANCE ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/kilometric_allowance',
        method: "GET"
      }

      const getKilometricAllowance = await Api(params)
      if (getKilometricAllowance.success) {
        setKilometricAllowance(getKilometricAllowance.data)
      }
    }
    if (!kilometricAllowance.length) {
      fetchData()
    }
  }, [kilometricAllowance]);

  // 
  // ─── HANDLE FILTERS ───────────────────────────────────────
  //
  const handleUserFilter = (e) => {
    setFilters({ ...filters, ...{ user_id: e } })
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginBottom: 40
    },
    cardHeader: {
      display: 'flex',
      gap: 20,
      justifyContent: 'flex-end',
      padding: '0 20px 10px 0',
      color: 'var(--text-color)'
    },
    cardContainer: {
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      '&>div:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    },
    card: {
      padding: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'var(--text-color)',
      fontSize: 14
    },
    category: {
      color: 'var(--text-color)'
    },
    actionIconsContainer: {
      width: 20,
      height: 20,
      marginRight: 10,
      color: 'var(--text-color)',
      '&>svg': {
        width: '20px'
      }
    },
    elements: {
      display: 'flex',
      gap: 20,
      alignItems: 'center'
    },
    clientContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    dot: {
      minWidth: 6,
      minHeight: 6,
      borderRadius: '100%',
      marginRight: 8
    },
    price: {
      marginLeft: 'auto',
      background: 'var(--activity-color)',
      padding: '3px 6px',
      color: 'var(--activity-text-color)'
    },
    filters: {
      display: 'flex',
      marginBottom: 40,
      gap: 10,
      '&>div': {
        width: '200px !important'
      }
    },
    thumbnail: {
      width: 35,
      height: 35,
      borderRadius: '100%'
    },
  })
  const classes = useStyle();

  // 
  // ─── SORT KILOMETRIC ALLOWANCE BY DATE ───────────────────────────────────────
  //
  const kilometricAllowanceDate = {};

  kilometricAllowance.length && kilometricAllowance.forEach((value) => {
    const categoryTitle = formatDate({ time: value.time_travel, display: 'date_month' });

    // Filter list by user
    if (filters.user_id && filters.user_id !== value.user_id) {
      return true;
    }

    if (!kilometricAllowanceDate[categoryTitle]) {
      kilometricAllowanceDate[categoryTitle] = {
        data: [],
        total_distance: 0,
        total_price: 0
      }
    }

    kilometricAllowanceDate[categoryTitle].data.push(value);
    kilometricAllowanceDate[categoryTitle].total_distance += value.distance;
    kilometricAllowanceDate[categoryTitle].total_price += value.price;
  })

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div>
    <div className={classes.filters}>
      <Formfields field={{
        type: "select", component: "select", name: "developer", label: "Collaborateur", dataLabel: 'name', dataValue: 'id', default: 0, data: [{ id: 0, name: 'Tous' }, ...window._DATA.users.map((value) => {
          return { id: value.user_id, name: `${value.firstname} ${value.name}` }
        })
        ], handleChange: handleUserFilter
      }} />
    </div>
    {Object.entries(kilometricAllowanceDate).map(([key, kilometersAllowance]) => {
      return <div key={key} className={classes.container}>
        <h2 className={classes.category}>{key}</h2>
        <div className={classes.cardHeader}>
          <span>{`Distance : ${kilometersAllowance.total_distance} km`}</span>
          <span>{`Prix : ${kilometersAllowance.total_price}€`}</span>
        </div>
        <div className={classes.cardContainer}>
          {kilometersAllowance.data.map((value) => {
            return <div key={value.kilometric_allowance_id} className={classes.card}>
              <div className={classes.elements}>
                <img className={classes.thumbnail} src={`/assets/img/team/${value.user_firstname.toLowerCase()}.jpg`} alt="" />
                <span>{formatDate({ time: value.time_travel, display: 'date' })}</span>
                <span>{value.description}</span>
                <div className={classes.clientContainer} style={{ color: '#FFB100' }}>
                  <span className={classes.dot} style={{ background: '#FFB100' }} />
                  <span>{value.username}</span>
                </div>
              </div>
              <div className={classes.elements}>
                <span>{`${window._DATA.types['vehicle'][value.vehicle].label} - ${window._DATA.types['vehicle_power'][value.vehicle_power].label}`}</span>
                <span>{`${value.distance} km`}</span>
                <span className={classes.price}>{`${value.price} €`}</span>
                <Link to={`/gestion/indemnites-kilometriques/${value.kilometric_allowance_id}/editer`} className={classes.actionIconsContainer}>
                  <EditIcon />
                </Link>
              </div>
            </div>
          })}
        </div>
      </div>
    })}
  </div>
};

export default KilometricAllowanceList;