// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useRef, useState } from 'react';
import { loremIpsum } from "lorem-ipsum";
import { createUseStyles } from 'react-jss';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import { useNotification } from '../../components/UseNotification'
import { copyToClipboard, autoSize } from '../../contexts/Utils';


// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const LoremIpsum = () => {
  const textAreaElement = useRef();

  const [count, setCount] = useState(2);
  const [sentences, setSentences] = useState([3, 8]);
  const [wordPerSentences, setWordPerSentences] = useState([8, 15])

  const { notification } = useNotification();

  useEffect(() => {
    autoSize(textAreaElement.current)
  }, [textAreaElement])

  const loremGenerated = loremIpsum({
    count: count,                // Number of "words", "sentences", or "paragraphs"
    format: "plain",         // "plain" or "html"
    paragraphLowerBound: sentences[0],  // Min. number of sentences per paragraph.
    paragraphUpperBound: sentences[1],  // Max. number of sentences per paragarph.
    random: Math.random,     // A PRNG function
    sentenceLowerBound: wordPerSentences[0],   // Min. number of words per sentence.
    sentenceUpperBound: wordPerSentences[1],  // Max. number of words per sentence.
    suffix: "\r\n\r\n",            // Line ending, defaults to "\n" or "\r\n" (win32)
    units: "paragraph",      // paragraph(s), "sentence(s)", or "word(s)"
  })

  // 
  // ─── HANDLE MULTIPLE RANGE ───────────────────────────────────────
  //
  const handleSentences = (e, newValue) => {
    setSentences(newValue);
    autoSize(textAreaElement.current)
  };

  // 
  // ─── HANDLE MULTIPLE RANGE ───────────────────────────────────────
  //
  const handleWordPerSentences = (e, newValue) => {
    setWordPerSentences(newValue);
    autoSize(textAreaElement.current)
  };

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      color: 'var(--text-color)',
      maxWidth: 800,
      margin: 'auto',
    },
    container: {
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      borderColor: 'transparent',
      padding: 20,
      background: 'var(--block-color)',
    },
    textarea: {
      width: 'calc(100% - 20px)',
      padding: '5px 10px',
      color: 'var(--text-color)',
      outline: 'none',
      fontFamily: 'Open-sans, sans-serif',
      lineHeight: 1.5,
      background: 'var(--block-color)',
      border: '1px solid var(--colored-element-color)',
      '&:focus': {
        border: '1px solid var(--colored-element-color)',
        background: 'rgba(255, 177, 0,0.2)'
      }
    },
    copy: {
      marginTop: '20px !important'
    },
    rangeContainer: {
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center',
      gap: 20
    },
    formTitles: {
      minWidth: 200
    }
  })

  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    <div className='tools-header'>
      <h1>Lorem ipsum generator</h1>
      <p>Le lorem ipsum est un texte de remplacement couramment utilisé pour démontrer la forme visuelle d'un document ou d'une police de caractères sans s'appuyer sur un contenu significatif.</p>
    </div>
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.rangeContainer}>
          <span className={classes.formTitles}>Paragraphes</span>
          <Slider defaultValue={count} max={20} min={1} aria-label="Default" valueLabelDisplay="auto" onChange={(e) => {
            setCount(e.target.value)
            autoSize(textAreaElement.current)
          }} />
        </div>
        <div className={classes.rangeContainer}>
          <span className={classes.formTitles}>Phrases par paragraphe</span>
          <Slider value={sentences} onChange={handleSentences} valueLabelDisplay="auto" min={1} max={50} />
        </div>
        <div className={classes.rangeContainer}>
          <span className={classes.formTitles}>Mots par phrase</span>
          <Slider value={wordPerSentences} onChange={handleWordPerSentences} valueLabelDisplay="auto" min={1} max={50} />
        </div>
      </div>
      <textarea ref={textAreaElement} className={classes.textarea + ' loremGeneratorInput'} rows='1' placeholder='Auto-Expanding Textarea' onChange={autoSize} value={loremGenerated} readOnly></textarea>
      <Button className={classes.copy} variant='contained' onClick={() => {
        copyToClipboard({ element: 'loremGeneratorInput' });
        notification({ variant: 'success', message: 'Text copié' })
      }}>Copier</Button>
    </div>
  </div >
};

export default LoremIpsum;