// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ChromePicker } from 'react-color'

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const ColorPicker = (props) => {
  // 
  // ─── INITIALISATION DES STATES ───────────────────────────────────────
  //
  const [color, setColor] = useState(props.defaultColor)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  // 
  // ─── OUVERTURE DE LA POPUP ───────────────────────────────────────
  //
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  // 
  // ─── FERMETURE DU COLORPICKER ───────────────────────────────────────
  //
  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  // 
  // ─── RECUPERATION DE LA COULEUR ET RETOURNER LA VALEUR AU COMPOSANT PARENT ───────────────────────────────────────
  //
  const handleChange = (color) => {
    props.color({ ...props, ...{ color: color.hex } })
  };

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    card: {
      background: 'var(--block-color)',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      cursor: 'pointer',
      width: 60,
      height: '100%',
      display: 'flex',
      marginRight: 10,
      flexDirection: "column",
      justifyContent: 'space-around',
      padding: 8,
      borderRadius: 4
    },
    color: {
      height: '100%',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    },
    colorDefault: {
      backgroundColor: "var(--background-color)",
      backgroundImage: "linear-gradient(45deg, var(--block-color) 25%, transparent 25%, transparent 75%, var(--block-color) 75%), linear-gradient(45deg, var(--block-color) 25%, transparent 25%, transparent 75%, var(--block-color) 75%)",
      backgroundSize: "20px 20px",
      backgroundPosition: "0 0, 10px 10px",
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  })

  const classes = useStyle()

  useEffect(() => {
    if (props.defaultColor !== color) {
      setColor(props.defaultColor)
    }
  }, [props.defaultColor, color])

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return (
    <>
      <div className={classes.card} onClick={handleClick}>
        <div className={classes.color + ` ${!color && classes.colorDefault}`} style={{ background: color }} />
      </div>
      {displayColorPicker ? <div className={classes.popover}>
        <div className={classes.cover} onClick={handleClose} />
        <ChromePicker color={color} onChange={handleChange} />
      </div> : null}
    </>
  );
};

export default ColorPicker;