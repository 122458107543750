// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Api from '../contexts/Api';
import Formfields from "../components/Formfields";
import { prepareFormData } from '../contexts/Utils';
import Button from '@mui/material/Button';
import { useNotification } from '../components/UseNotification';
import ArrowBack from '../components/ArrowBack';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const UserManage = (props) => {

  const { notification } = useNotification()
  let navigate = useNavigate();
  let defaults = props.defaults || {}

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  const handleChange = async (event) => {
    event.preventDefault();

    let data = prepareFormData({ formId: 'myForm' })

    // Add user_id to request
    if (props.update && data.fields) {
      data.fields.user_id = defaults.user_id
    }

    const params = {
      data,
      endpoint: '/users',
      method: props.update ? 'PUT' : 'POST'
    }

    const createUser = await Api(params)
    notification({ variant: createUser.success ? 'success' : 'error', message: createUser.message })

    if (createUser.success) {
      return navigate("/gestion/utilisateurs");
    }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      gap: 20
    },
    card: {
      flex: 1,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 20,
      borderRadius: 20,
      '&>div:not(:first-child)': {
        marginTop: 20
      }
    },
    submit: {
      margin: '20px auto 10px !important'
    }
  })
  const classes = useStyle();

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    {
      fields: [
        { type: "radio", component: "radio", name: "gender", label: "Genre", value: [{ label: 'Homme', value: '2' }, { label: 'Femme', value: '1' }] },
        { type: "text", component: "text", name: "name", label: "Nom" },
        { type: "text", component: "text", name: "firstname", label: "Prénom" },
        { type: "text", component: "text", name: "phone", label: "Téléphone" },
        { type: "email", component: "text", name: "email", label: "Email" },
        { type: "password", component: "text", name: "password", label: "Mot de passe", cond: !props.update },
      ]
    },
    {
      fields: [
        { type: "text", component: "text", name: "company", label: "Société" },
        { type: "text", component: "text", name: "address", label: "Adresse" },
        { type: "text", component: "text", name: "cp", label: "Code postal" },
        { type: "text", component: "text", name: "city", label: "Ville" },
        { type: "text", component: "text", name: "function", label: "Fonction" },
        { type: "text", component: "text", name: "job", label: "Poste" },
      ]
    }
  ]

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <div>
    <ArrowBack link='/gestion/utilisateurs' />
    <form onSubmit={handleChange} className={classes.form} name="myForm">
      <div className={classes.container}>
        {formFields.map((element, index) => {
          return <div key={index} className={classes.card}>
            {element.fields.map((el) => {
              if (el.hasOwnProperty('cond') && !el.cond) return true
              if (defaults[el.name] && !el.default) el.default = defaults[el.name]

              // 
              // ─── DISPLAY FIELDS ───────────────────────────────────────
              //
              return <Formfields key={el.name} field={el} slug={props.slug} />
            })}
          </div>
        })}
      </div>
      <Button variant="contained" className={classes.submit} onClick={handleChange}>Enregistrer</Button>
    </form>
  </div >
};

export default UserManage;