// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams, Link } from 'react-router-dom';
import Api from '../../contexts/Api';
import { formatDate, getValue } from '../../contexts/Utils';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import RoomIcon from '@mui/icons-material/Room';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import BarChart from '../../components/Charts/BarChart';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ClientEdit = () => {
  const clientParams = useParams()
  const [client, setClient] = useState({})

  // 
  // ─── GET WEBSITE TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/clients',
        query: {
          username: clientParams.username,
          view: true
        },
        method: "GET"
      }

      const getClients = await Api(params)
      if (getClients.success) {
        setClient(getClients.data)
      }
    }
    if (!Object.keys(client).length) {
      fetchData()
    }
  }, [client, clientParams])

  // 
  // ─── CLIENT STATUS COLORS ───────────────────────────────────────
  //
  const clientStatus = {
    1: { color: '#c1801e', background: '#ffe8cb' },
    2: { color: '#229a16', background: '#e4f8dd' },
    3: { color: '#bd0000', background: '#ffcbcb' },
  }

  // 
  // ─── PROJECT STATUS COLORS ───────────────────────────────────────
  //
  const projectStatus = {
    1: '#e0e0e0',
    2: '#d40477',
    3: '#4dcd40',
    4: '#c73636'
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginBottom: 40
    },
    headerWrapper: {
      display: 'flex',
      gap: 20,
      '&>div:nth-child(1)': {
        width: 'calc(40% - 40px)'
      },
      '&>div:nth-child(2)': {
        width: 'calc(60% - 40px)'
      }
    },
    card: {
      padding: 20,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
    },
    logo: {
      width: 100
    },
    infoContainer: {
      color: 'var(--text-color)',
    },
    infoHeader: {
      display: 'flex',
      gap: 40
    },
    infoContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    infoTitle: {
      fontWeight: 'bold',
      fontSize: 20
    },
    infoUsername: {
      fontSize: 14,
      marginBottom: 8,
      fontWeight: 100
    },
    clientStatus: {
      display: 'block',
      padding: '3px 8px',
      width: 'fit-content',
      fontSize: 13,
      fontWeight: 'bold'
    },
    infosContactContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      marginTop: 30
    },
    infoContact: {
      display: 'flex',
      alignItems: 'center',
      gap: 15
    },
    projectsWrapper: {
      marginTop: 40
    },
    projectsContainer: {
      marginTop: 40,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      '&>div:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    },
    projectTitle: {
      color: 'var(--text-color)'
    },
    projectCard: {
      padding: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'var(--text-color)',
      fontSize: 14
    },
    elements: {
      display: 'flex',
      gap: 20,
      alignItems: 'center'
    },
    projectPrice: {
      marginLeft: 'auto',
      background: 'var(--activity-color)',
      padding: '3px 6px',
      color: 'var(--activity-text-color)'
    },
    projectStatusContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    dot: {
      minWidth: 6,
      minHeight: 6,
      borderRadius: '100%',
      marginRight: 8
    },
    projectMaintenance: {
      marginLeft: 'auto',
      background: 'rgba(255, 177, 0,0.2)',
      padding: '3px 6px',
      color: 'var(--colored-element-color)'
    },
    projectStatsContainer: {
      display: 'flex',
      marginTop: 40,
      gap: 20,
      '&>div:nth-child(1)': {
        width: 'calc(60% - 40px)'
      },
      '&>div:nth-child(2)': {
        width: 'calc(40% - 20px)'
      },
    },
    projectStats: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      color: 'var(--text-color)'
    },
    projectStatsCards: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '&>span': {
        fontWeight: 'bold',
        fontSize: '3.5em'
      },
      '&>p': {
        marginTop: 0
      }
    },
    actionIconsContainer: {
      width: 20,
      height: 20,
      marginRight: 10,
      color: 'var(--text-color)',
      '&>svg': {
        width: '20px'
      }
    },
    appointmentContainer: {
      padding: 20,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      '&>div:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    },
    appointmentCard: {
      display: 'flex',
      gap: 40,
      alignItems: 'center',
      color: 'var(--text-color)',
      padding: 10
    },
    appointmentDate: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&>span:nth-child(1)': {
        fontWeight: 'bold',
        fontSize: '1.4em',
      },
      '&>span:nth-child(2)': {
        fontSize: '0.8em',
        color: 'var(--subtitle-color)',
        transform: 'translateY(-2px)'
      }
    },
    appointmentSubject: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 'auto',
      '&>span:nth-child(1)': {
        fontWeight: 'bold',
      },
      '&>span:nth-child(2)': {
        fontSize: '0.8em',
        color: 'var(--subtitle-color)'
      }
    }
  })
  const classes = useStyle();

  let chartData = {}
  let projectNumber = 0;
  let projectSales = 0;

  if (Object.keys(client).length) {
    client.projects.map((project) => {
      const month = formatDate({ time: project.time_end, display: 'month' })
      chartData[month] = (chartData[month] || 0) + project.price;

      if (project.status === 3) {
        projectSales += project.price
        projectSales += project.maintenance
      }
      projectNumber++
    })
  }

  return Boolean(Object.keys(client).length) && <div>
    <div className={classes.headerWrapper}>
      <div className={classes.card + ' ' + classes.infoContainer}>
        <div className={classes.infoHeader}>
          <svg viewBox="0 0 694 471" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.logo}>
            <g clipPath="url(#clip0)">
              <path d="M471.636 223H593.604C598.224 223 600.996 220.228 600.996 215.608V189.12C600.996 184.5 598.224 181.728 593.604 181.728H509.52V30.192C509.52 25.572 506.748 22.8 502.128 22.8H471.636C467.016 22.8 464.244 25.572 464.244 30.192V215.608C464.244 220.228 467.016 223 471.636 223Z" fill="var(--text-color)" />
              <path d="M549.636 449H672.22C676.84 449 679.612 446.228 679.612 441.608V415.12C679.612 410.5 676.84 407.728 672.22 407.728H587.52V369.536H665.752C670.372 369.536 673.144 366.764 673.144 362.144V335.656C673.144 331.036 670.372 328.264 665.752 328.264H587.52V290.072H672.22C676.84 290.072 679.612 287.3 679.612 282.68V256.192C679.612 251.572 676.84 248.8 672.22 248.8H549.636C545.016 248.8 542.244 251.572 542.244 256.192V441.608C542.244 446.228 545.016 449 549.636 449Z" fill="var(--text-color)" />
              <path fillRule="evenodd" clipRule="evenodd" d="M12 123C12 178.228 56.7715 223 112 223C113.618 223 115.228 222.962 116.828 222.886C117.207 222.961 117.599 223 118 223L206 223C209.314 223 212 220.314 212 217L212 129C212 128.599 211.961 128.207 211.886 127.828C211.962 126.228 212 124.618 212 123C212 67.7715 167.228 23 112 23C56.7715 23 12 67.7715 12 123Z" fill="#FFB100" fillOpacity="0.7" />
              <path fillRule="evenodd" clipRule="evenodd" d="M112 449C167.228 449 212 404.228 212 349C212 347.382 211.962 345.772 211.886 344.172C211.961 343.793 212 343.401 212 343V255C212 251.686 209.314 249 206 249L118 249C117.599 249 117.207 249.039 116.828 249.114C115.228 249.038 113.618 249 112 249C56.7715 249 12 293.772 12 349C12 404.228 56.7715 449 112 449Z" fill="#F4D180" fillOpacity="0.4" />
              <path fillRule="evenodd" clipRule="evenodd" d="M338 223C393.228 223 438 178.228 438 123C438 67.7715 393.228 23 338 23C282.772 23 238 67.7715 238 123C238 124.618 238.038 126.228 238.114 127.828C238.039 128.207 238 128.599 238 129L238 217C238 220.314 240.686 223 244 223H332C332.401 223 332.793 222.961 333.172 222.886C334.772 222.962 336.382 223 338 223ZM338 178C368.376 178 393 153.376 393 123C393 92.6243 368.376 68 338 68C307.624 68 283 92.6243 283 123C283 153.376 307.624 178 338 178Z" fill="#FFB100" />
              <path d="M309.308 449H335.18C339.492 449 342.572 446.844 343.804 442.84L377.068 342.432L410.332 442.84C411.564 446.844 414.644 449 418.956 449H444.828C449.14 449 452.22 446.844 453.452 442.84L515.36 257.116C516.9 252.188 514.436 248.8 509.2 248.8H476.552C472.24 248.8 469.16 250.956 467.928 254.96L432.816 360.912L397.088 254.96C395.856 250.956 392.776 248.8 388.464 248.8H368.136C363.824 248.8 361.052 250.956 359.82 254.96L322.86 366.764L285.9 254.96C284.668 250.956 281.896 248.8 277.584 248.8H244.936C239.7 248.8 237.236 252.188 238.776 257.116L300.684 442.84C301.916 446.844 304.996 449 309.308 449Z" fill="var(--text-color)" />
            </g>
          </svg>
          <div className={classes.infoContent}>
            <span className={classes.infoTitle}>{client.company}</span>
            <span className={classes.infoUsername}>{`username : ${client.username}`}</span>
            <span className={classes.clientStatus} style={{ color: clientStatus[client.status].color, background: clientStatus[client.status].background }}>{getValue(window, ['_DATA', 'types', 'client_status', [client.status], 'label'])}</span>
          </div>
        </div>
        <div className={classes.infosContactContainer}>
          {client.firstname && <div className={classes.infoContact}>
            <PersonIcon />
            <span>{`${client.firstname} ${client.name}`}</span>
          </div>}
          {client.email && <div className={classes.infoContact}>
            <EmailIcon />
            <span>{client.email}</span>
          </div>}
          {client.phone && <div className={classes.infoContact}>
            <LocalPhoneIcon />
            <span>{client.phone}</span>
          </div>}
          {client.address && <div className={classes.infoContact}>
            <RoomIcon />
            <span>{`${client.address}, ${client.cp} ${client.city}`}</span>
          </div>}
          <div className={classes.infoContact}>
            <StarBorderIcon />
            <span>{`${client.review ? "Le client a laissé un avis Google" : "Le client n'a pas encore laissé d'avis Google"}`}</span>
          </div>
        </div>
      </div>
      {Boolean(client.appointments.length) && <div className={classes.appointmentContainer}>
        {client.appointments && client.appointments.map((appointment) => {
          return <div className={classes.appointmentCard}>
            <div className={classes.appointmentDate}>
              <span>{formatDate({ time: appointment.time_start, display: 'day_number' })}</span>
              <span>{formatDate({ time: appointment.time_start, display: 'day' }).substr(0, 3)}</span>
            </div>
            <div className={classes.appointmentSubject}>
              <span>{appointment.name}</span>
              <span>{`${formatDate({ time: appointment.time_start, display: 'timer' })}-${formatDate({ time: appointment.time_end, display: 'timer' })}`}</span>
            </div>
            <span className={classes.appointmentStatus}>{getValue(window, ['_DATA', 'types', 'appointment_status', appointment.status, 'label'])}</span>
          </div>
        })}
      </div>}
    </div>
    {Boolean(client.projects.length) && <div className={classes.projectsWrapper + ' tools-header'}>
      <h1 className={classes.projectTitle}>Projets liés au client</h1>
      <div className={classes.projectStatsContainer}>
        <div className={classes.card}>
          <BarChart data={chartData} />
        </div>
        <div className={classes.projectStats}>
          <div className={classes.projectStatsCards + ' ' + classes.card}>
            <span>{projectNumber}</span>
            <p>Nombre de projets</p>
          </div>
          <div className={classes.projectStatsCards + ' ' + classes.card}>
            <span>{`${projectSales}€`}</span>
            <p>Résultat</p>
          </div>
        </div>
      </div>
      <div className={classes.projectsContainer}>
        {client.projects.map((value) => {
          return <div key={value.project_id} className={classes.projectCard}>
            <div className={classes.elements}>
              <span>{value.name}</span>
              <div className={classes.projectStatusContainer} style={{ color: projectStatus[value.status] }}>
                <span className={classes.dot} style={{ background: projectStatus[value.status] }} />
                <span>{getValue(window, ['_DATA', 'types', 'project_status', value.status, 'label'])}</span>
              </div>
            </div>
            <div className={classes.elements}>
              <span className={classes.projectPrice}>{`Prix ${value.price}€`}</span>
              {value.maintenance && <span className={classes.projectMaintenance}>{`Maintenance ${value.maintenance}€ /an`}</span>}
              <Link to={`/gestion/projets/${value.project_id}/editer`} className={classes.actionIconsContainer}>
                <EditIcon />
              </Link>
            </div>
          </div>
        })}
      </div>
    </div>}
  </div>
};

export default ClientEdit;