// 
// ─── IMPORT DES ELEMENTS NECESSAIRESS ───────────────────────────────────────
//
import React from 'react';
import OfferManage from '../../containers/OfferManage';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const OfferAdd = () => {
  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <OfferManage />
};

export default OfferAdd;