// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TimeTrackingManage from '../../containers/TimeTrackingManage';
import Api from '../../contexts/Api'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const TimeTrackingEdit = () => {
  const timeTrackingParams = useParams()
  const [timeTracking, setTimeTracking] = useState()

  // 
  // ─── GET TIME TRACKING TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/time_tracking',
        query: {
          time_tracking_id: timeTrackingParams.time_tracking_id
        },
        method: "GET"
      }

      const getTimeTracking = await Api(params)
      if (getTimeTracking.success) {
        setTimeTracking(getTimeTracking.data)
      }
    }
    if (!timeTracking) {
      fetchData()
    }
  }, [timeTracking, timeTrackingParams])

  return <>
    {timeTracking && <TimeTrackingManage defaults={timeTracking} update={true} />}
  </>
};

export default TimeTrackingEdit;