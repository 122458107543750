// 
// ─── IMPORT DES ELEMENTS NECESSAIRESS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import Api from '../../contexts/Api';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const UsersList = () => {

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [users, setUsers] = useState([]);

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: "/users",
        method: "GET"
      }

      const getClients = await Api(params)
      if (getClients.success) {
        setUsers(getClients.data)
      }
    }
    if (!users.length) {
      fetchData()
    }
  }, [users])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginBottom: 40
    },
    cardHeader: {
      display: 'flex',
      gap: 20,
      justifyContent: 'flex-end',
      padding: '0 20px 10px 0',
      color: 'var(--text-color)'
    },
    cardContainer: {
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      '&>div:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    },
    card: {
      padding: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'var(--text-color)',
      fontSize: 14
    },
    actionsContainer: {
      display: 'flex',
    },
    actionIconsContainer: {
      width: 20,
      height: 20,
      marginLeft: 5,
      color: 'var(--text-color)',
      '&>svg': {
        width: '20px'
      }
    },
    elements: {
      display: 'flex',
      gap: 20,
      alignItems: 'center'
    },
    userContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    dot: {
      minWidth: 6,
      minHeight: 6,
      borderRadius: '100%',
      marginRight: 8
    },
    userJob: {
      marginLeft: 'auto',
      background: 'var(--activity-color)',
      padding: '3px 6px',
      color: 'var(--activity-text-color)'
    },
    projectMaintenance: {
      marginLeft: 'auto',
      background: 'rgba(255, 177, 0,0.2)',
      padding: '3px 6px',
      color: 'var(--colored-element-color)'
    },
    projectStatus: {
      padding: '3px 6px',
    },
    thumbnail: {
      width: 40,
      height: 40,
      borderRadius: '100%',
    },
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.cardContainer}>
    {users.map((user) => {
      const name = <img className={classes.thumbnail} src={`/assets/img/team/${user.firstname.toLowerCase()}.jpg`} alt="" />

      return <div key={user.user_id} className={classes.card}>
        <div className={classes.elements}>
          {name}
          <span>{`${user.name} ${user.firstname}`}</span>
          <div className={classes.userContainer} style={{ color: "#FFB100" }}>
            <span className={classes.dot} style={{ background: "#FFB100" }} />
            <span>{user.function}</span>
          </div>
          <span className={classes.userJob}>{user.job}</span>
          <span>{user.email}</span>
          <span>{user.phone}</span>
        </div>
        <div className={classes.elements}>
          <div className={classes.actionsContainer}>
            <Tooltip title="Éditer">
              <Link to={`/gestion/utilisateurs/${user.user_id}/editer`} className={classes.actionIconsContainer}>
                <EditIcon />
              </Link>
            </Tooltip>
          </div>
        </div>
      </div>
    })}
  </div>
};

export default UsersList;