// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Api from '../contexts/Api';
import Formfields from "../components/Formfields";
import { prepareFormData, getValue } from '../contexts/Utils';
import Button from '@mui/material/Button';
import { useNotification } from '../components/UseNotification';
import ArrowBack from '../components/ArrowBack';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const ProjectManage = (props) => {
  const { notification } = useNotification()
  let navigate = useNavigate();
  let defaults = props.defaults || {};

  const [maintenance, setMaintenance] = useState(defaults.hasOwnProperty('maintenance') ? defaults.maintenance || 0 : 1);
  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);
  const [offers, setOffers] = useState([]);

  const handleMaintenance = (e) => {
    setMaintenance(e)
  }

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'myForm' });

    if (data.errors) {
      setErrors(data.errors)
    } else {
      if (!data.fields.maintenance) {
        data.fields.maintenance = null;
      }
      let formData = {};
      if (props.update) {
        formData.project_id = defaults.project_id
        formData.fields = data.fields
      } else {
        const files = ['devis', 'invoice'];
        formData = new FormData();

        files.forEach((file) => {
          if (data.fields[file]) {
            formData.append('files[]', data.fields[file], `${file}-${data.fields[file].name}`);
            delete data.fields[file];
          }
        })
        formData.append('fields', JSON.stringify(data))
      }

      const params = {
        data: formData,
        endpoint: '/projects',
        method: props.update ? 'PUT' : 'POST',
        removeHeader: props.update ? false : true
      }

      const createProject = await Api(params)
      notification({ variant: createProject.success ? 'success' : 'error', message: createProject.message })

      if (createProject.success) {
        return navigate("/gestion/projets");
      }
    }
  }

  // 
  // ─── GET CLIENTS VALUES FOR USENAME ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/clients',
        query: {
          fields: 'username,client_id'
        },
        method: "GET"
      }
      const getClients = await Api(params)
      if (getClients.success) {
        setClients(getClients.data)
      }
    }
    if (!clients.length) { fetchData() }

  }, [clients])

  // 
  // ─── GET OFFERS VALUES ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/offers',
        query: {
          fields: 'name,offer_id'
        },
        method: "GET"
      }
      const getOffers = await Api(params)
      if (getOffers.success) {
        setOffers(getOffers.data)
      }
    }
    if (!offers.length) { fetchData() }

  }, [offers])

  // 
  // ─── DELETE FILE ───────────────────────────────────────
  //
  const deleteFile = async (opt) => {
    const params = {
      endpoint: `/projects/file`,
      method: "DELETE",
      data: {
        name: opt.name,
        filename: opt.filename,
        project_id: defaults.project_id
      }
    }
    const removeFile = await Api(params)

    // 
    // ─── UPDATE FILE INFORMATIONS IF SUCCESSFUL ───────────────────────────────────────
    //
    notification({ variant: removeFile.success ? 'success' : 'error', message: removeFile.message })
  }

  // 
  // ─── UPLOAD FILE ───────────────────────────────────────
  //
  const uploadFile = async (event) => {
    const element = event.target
    let filename

    // 
    // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
    //
    const formData = new FormData();
    if (element.files[0]) {
      filename = `${element.name}-${element.files[0].name}`;
      formData.append('file', element.files[0], filename);
    }
    formData.append('name', event.target.name)
    formData.append('project_id', defaults.project_id)

    // 
    // ─── API REQUEST FOR FILE UPLOAD ───────────────────────────────────────
    //
    const params = {
      data: formData,
      endpoint: '/projects/file',
      method: 'POST',
      removeHeader: true
    }
    const addFile = await Api(params);

    // 
    // ─── UPDATE FILE INFORMATIONS IF SUCCESSFUL ───────────────────────────────────────
    //
    notification({ variant: addFile.success ? 'success' : 'error', message: addFile.message })
  }

  // 
  // ─── RECUPERATION DE L'ID DU CLIENT GRACE AU USERNAME ───────────────────────────────────────
  //
  if (defaults.username) {
    const defaultClient = clients.filter(v => v.username === defaults.username);
    if (defaultClient.length) {
      defaults['client_id'] = getValue(defaultClient, [0, 'client_id'])
    }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      gap: 20,
      marginBottom: 40
    },
    card: {
      flex: 1,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 20,
      borderRadius: 20,
      '&>div:not(:first-child)': {
        marginTop: 20
      }
    },
    submit: {
      margin: '20px auto 10px !important'
    },
    files: {
      display: 'flex',
      alignItems: 'center',
      gap: 20
    },
    form: {
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      '@media screen and (max-width:1200px)': {
        flexDirection: 'column'
      },
      '&>div:nth-child(2)': {
        marginTop: 0
      }
    }
  })
  const classes = useStyle();

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    {
      fields: [
        {
          type: "select", component: "select", name: "status", label: "État du projet", dataLabel: 'name', dataValue: 'id', default: defaults.status || 1, data: Object.values(window._DATA.types['project_status']).map((type) => {
            return { id: type.value, name: type.label }
          }),
        },
        { component: "text", name: "name", label: "Nom du projet", required: true },
        { component: "text", multiline: true, rows: 4, name: "description", label: "Description" },
        { type: "select", component: "select", name: "client_id", label: "Username du client", data: clients, default: defaults.client_id || '-', dataLabel: 'username', dataValue: "client_id" },
        { type: "select", component: "select", name: "offer_id", label: "Type d'offre", dataLabel: 'name', dataValue: 'offer_id', default: defaults.offer_id || '', data: offers },
        { type: 'date', component: "datepicker", name: "time_start", label: "Date de démarrage", required: true },
        { type: 'date', component: "datepicker", name: "time_end", label: "Date de fin" },
        { component: "text", name: "price", label: "Prix" },
        { type: "radio", component: "switch", name: "", label: "Maintenance", default: defaults.hasOwnProperty('maintenance') ? defaults.maintenance || 0 : true, value: 1, handleChange: handleMaintenance },
        { component: "text", name: "maintenance", label: "Prix de la maintenance (annuel)", cond: maintenance },
      ]
    }
  ]

  const fileFields = {
    title: 'Télécharger des fichiers',
    form: 'upload_files',
    fields: [
      { component: "file", name: "devis", label: "Devis", size: 'calc(50% - 10px)', default: defaults.devis },
      { component: "file", name: "invoice", label: "Facture", size: 'calc(50% - 10px)', default: defaults.invoice },
    ]
  }

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <div>
    <ArrowBack link='/gestion/projets' />
    <form onSubmit={handleChange} name="myForm">
      <div className={classes.container}>
        {formFields.map((element, index) => {
          return <div key={index} className={classes.card}>
            {element.fields.map((el) => {
              if (el.hasOwnProperty('cond') && !el.cond) return true
              if (defaults[el.name] && !el.default) el.default = defaults[el.name]

              // 
              // ─── DISPLAY FIELDS ───────────────────────────────────────
              //
              return <Formfields key={el.name} field={{ ...el, error: getValue(errors, [el.name, 'message']) }} slug={props.slug} />
            })}
            {!props.update && <div className={classes.files}>
              <Formfields field={{ component: "file", name: "devis", label: "Devis" }} />
              <Formfields field={{ component: "file", name: "invoice", label: "Facture" }} />
            </div>}
          </div>
        })}
      </div>
      <Button variant="contained" className={classes.submit} onClick={handleChange}>Enregistrer</Button>
    </form>
    {Boolean(Object.keys(defaults).length) && <form className={classes.form + ' ' + classes.card} name='upload_files'>
      {fileFields.fields.map((field) => {
        return <Formfields key={field.name} field={{ ...field, handleChange: uploadFile, handleRemove: deleteFile }} />
      })}
      {fileFields.action && <Button variant="contained" className={classes.btn} onClick={fileFields.action}>Sauvegarder</Button>}
    </form>}
  </div >
};

export default ProjectManage;