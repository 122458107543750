// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../contexts/Api';
import { createUseStyles } from 'react-jss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const OfferList = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [offers, setOffers] = useState([])

  // 
  // ─── GET ALL OFFERS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: "/offers",
        method: "GET"
      }

      const getOffers = await Api(params)
      if (getOffers.success) {
        setOffers(getOffers.data)
      }
    }
    if (!offers.length) {
      fetchData()
    }
  }, [offers])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
      gridGap: '1rem',
      marginBottom: 40
    },
    linkCard: {
      display: 'flex',
      flexDirection: 'column',
      background: 'var(--block-color)',
      color: 'var(--text-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 10,
      textDecoration: 'none'
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    title: {
      fontWeight: 'bold',
      marginBottom: 10
    },
    reference: {
      color: 'var(--reference-color)',
    },
    financial: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    prices: {
      '& > span:nth-child(1)': {
        marginRight: 20
      }
    }
  })
  const classes = useStyle();

  // 
  // ─── ADD ACTIONS TO THE TABLELIST ───────────────────────────────────────
  //

  return Boolean(offers.length) && <div className={classes.container}>
    {offers.map((offer) => {
      return <Link to={`${offer.offer_id}/editer`} className={classes.linkCard}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{offer.name}</span>
          <span className={classes.reference}>{offer.reference}</span>
        </div>
        <div className={classes.financial}>
          <div className={classes.prices}>
            <span style={{ color: 'var(--colored-element-color)' }}>{`${offer.price.toFixed(2)}€ HT`}</span>
            <span>{`${(offer.price * (1 + (offer.tva / 100))).toFixed(2)}€ TTC`}</span>
          </div>
          <span>{`TVA ${offer.tva}%`}</span>
        </div>
      </Link>
    })}
  </div>
};

export default OfferList;