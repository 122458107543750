/* eslint-disable react-hooks/exhaustive-deps */
// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom';
import Api from '../../contexts/Api';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import TaskIcon from '@mui/icons-material/Task';
import SearchIcon from '@mui/icons-material/Search';
import { formatDate } from '../../contexts/Utils';

const WebsitesList = () => {
  // 
  // ─── DEFINITION DES STATES ───────────────────────────────────────
  //
  const [websites, setWebsites] = useState({ list: [], filtred: [] })

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    cardHeader: {
      display: 'flex',
      gap: 20,
      justifyContent: 'flex-end',
      padding: '0 20px 10px 0',
      color: 'var(--text-color)'
    },
    cardContainer: {
      marginBottom: 40,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      '&>div:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    },
    card: {
      padding: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'var(--text-color)',
      fontSize: 14
    },
    category: {
      color: 'var(--text-color)'
    },
    actionsContainer: {
      display: 'flex',
    },
    actionIconsContainer: {
      width: 20,
      height: 20,
      marginLeft: 5,
      color: 'var(--text-color)',
      '&>svg': {
        width: '20px'
      }
    },
    elements: {
      display: 'flex',
      gap: 20,
      alignItems: 'center'
    },
    clientContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    dot: {
      minWidth: 6,
      minHeight: 6,
      borderRadius: '100%',
      marginRight: 8
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: 10,
      color: 'var(--subtitle-color)'
    },
    filtersSearch: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    filtersSearchInput: {
      background: 'none',
      border: '1px solid var(--divider-color)',
      padding: 5,
      width: 240,
      color: 'var(--text-color)',
      outline: 'none'
    }
  })

  const classes = useStyle()

  // 
  // ─── RECUPERATION DES SITES INTERNET ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/websites',
        method: "GET"
      }

      const getWebsites = await Api(params)
      if (getWebsites.success) {
        let websitesArray = []
        getWebsites.data.map((value) => (
          websitesArray.push({
            favicon: <img height="16" width="16" className={classes.favicon} src={`https://s2.googleusercontent.com/s2/favicons?domain_url=https://${value.domain}`} alt={`${value.domain} favicon`} />,
            ...value
          })
        ))
        setWebsites({ list: websitesArray, filtred: [] })
      }
    }
    if (!websites.list.length) {
      fetchData()
    }
  }, [websites])

  const handleSearch = (e) => {
    const value = e.target.value;
    const tmpWebsites = websites.list;
    const tmpList = tmpWebsites.filter(element => element.domain.includes(value) || element.username.includes(value));

    setWebsites({ ...websites, filtred: tmpList })
  }

  let websiteDisplay = websites.list;

  if (websites.filtred.length) {
    websiteDisplay = websites.filtred
  }

  return <>
    <h1>Sites internet</h1>
    <div className={classes.filters}>

      <div className={classes.filtersSearch}>
        <input type="text" className={classes.filtersSearchInput} onChange={(e) => handleSearch(e)} />
        <SearchIcon />
      </div>
    </div>
    <div className={classes.cardContainer}>
      {websiteDisplay.map((website) => {
        return <div key={website.website_id} className={classes.card}>
          <div className={classes.elements}>
            {website.favicon}
            <span>{website.domain}</span>
            <div className={classes.clientContainer} style={{ color: '#FFB100' }}>
              <span className={classes.dot} style={{ background: '#FFB100' }} />
              <span>{website.username}</span>
            </div>
          </div>
          <div className={classes.elements}>
            <span>{`Mise en ligne : ${formatDate({ time: website.time_online, display: 'date' })}`}</span>
            <div className={classes.actionsContainer}>
              <Tooltip title="Détail du site">
                <Link to={`/gestion/sites/${website.domain}`} className={classes.actionIconsContainer}>
                  <SearchIcon />
                </Link>
              </Tooltip>
              <Tooltip title="Aller sur le site">
                <a href={`https://${website.domain}`} target="blank" className={classes.actionIconsContainer}>
                  <LanguageIcon />
                </a>
              </Tooltip>
              <Tooltip title="Éditer">
                <Link to={`/gestion/sites/${website.domain}/editer`} className={classes.actionIconsContainer}>
                  <EditIcon />
                </Link>
              </Tooltip>
              <Tooltip title="Checklist">
                <Link to={`/gestion/sites/${website.domain}/checklist`} className={classes.actionIconsContainer}>
                  <TaskIcon />
                </Link>
              </Tooltip>
            </div>
          </div>
        </div>
      })}
    </div >
  </>
};

export default WebsitesList;