// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const QuoteEdit = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <h1>404 Not Found</h1>
};

export default QuoteEdit;