// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import ClientManage from '../../containers/ClientManage'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ClientAdd = () => {
  return <>
    <ClientManage />
  </>
};

export default ClientAdd;