// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../contexts/Api';
import { createUseStyles } from 'react-jss'
import { formatDate, getValue } from '../../contexts/Utils';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ProjectsList = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [projects, setProjects] = useState({ list: [], filtred: [] })

  // 
  // ─── GET ALL PROJECTS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: `/projects`,
        method: "GET"
      }

      const getClients = await Api(params)
      if (getClients.success) {
        setProjects({ list: getClients.data, filtred: [] })
      }
    }
    if (!projects.list.length) {
      fetchData()
    }
  }, [projects])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    title: {
      color: 'var(--text-color)',
      margin: '0 auto 40px'
    },
    cardContainer: {
      marginBottom: 40,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      '&>div:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    },
    actionsContainer: {
      display: 'flex',
    },
    actionIconsContainer: {
      width: 20,
      height: 20,
      marginLeft: 5,
      color: 'var(--text-color)',
      '&>svg': {
        width: '20px'
      }
    },
    projectContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    dot: {
      minWidth: 6,
      minHeight: 6,
      borderRadius: '100%',
      marginRight: 8
    },
    projectPrice: {
      marginLeft: 'auto',
      background: 'var(--activity-color)',
      padding: '3px 6px',
      color: 'var(--activity-text-color)'
    },
    projectMaintenance: {
      marginLeft: 'auto',
      background: 'rgba(255, 177, 0,0.2)',
      padding: '3px 6px',
      color: 'var(--colored-element-color)'
    },
    projectStatus: {
      padding: '3px 6px',
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      padding: 10,
      marginBottom: 40
    },
    lines: {
      alignItems: 'center',
      color: 'var(--text-color)',
      fontSize: 14,
      borderBottom: '1px solid var(--divider-color)',
      '&>td': {
        padding: '14px 10px'
      }
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 10,
      color: 'var(--subtitle-color)'
    },
    filtersBtn: {
      display: 'flex',
      gap: 40,
      cursor: 'pointer',
      '& > div:hover ': {
        color: 'var(--secondary-color)',
      }
    },
    filtersSearch: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    filtersSearchInput: {
      background: 'none',
      border: '1px solid var(--divider-color)',
      padding: 5,
      width: 240,
      color: 'var(--text-color)',
      outline: 'none'
    }
  })
  const classes = useStyle()

  // 
  // ─── PROJECT STATUS COLORS ───────────────────────────────────────
  //
  const projectStatus = {
    1: '#e0e0e0',
    2: '#d40477',
    3: '#4dcd40',
    4: '#c73636',
  }

  // 
  // ─── SEARCH FILTER ON PROJECT LIST ───────────────────────────────────────
  //
  const handleSearch = (e) => {
    const value = e.target.value;
    const tmpProjects = projects.list;
    const tmpList = tmpProjects.filter(element => element.name.toLowerCase().includes(value) || element.username.includes(value));
    setProjects({ ...projects, filtred: tmpList })
  }

  let projectsDisplay = projects.list;

  if (projects.filtred.length) {
    projectsDisplay = projects.filtred
  }

  return <>
    <h1 className={classes.title}>Projets</h1>
    <div className={classes.filters}>
      <div className={classes.filtersBtn}>
        <div onClick={() => setProjects({ list: projects.list, filtred: [] })}>Tous les projets</div>
        <div onClick={() => setProjects({ list: projects.list, filtred: projects.list.filter(element => element.status === 1) })}>À faire</div>
        <div onClick={() => setProjects({ list: projects.list, filtred: projects.list.filter(element => element.status === 2) })}>En cours</div>
        <div onClick={() => setProjects({ list: projects.list, filtred: projects.list.filter(element => element.status === 3) })}>Terminés</div>
        <div onClick={() => setProjects({ list: projects.list, filtred: projects.list.filter(element => element.status === 4) })}>Annulés</div>
      </div>
      <div className={classes.filtersSearch}>
        <input type="text" className={classes.filtersSearchInput} onChange={(e) => handleSearch(e)} />
        <SearchIcon />
      </div>
    </div>
    <table className={classes.table}>
      <tbody className={classes.cardContainer}>
        {projectsDisplay.map((project, index) => {
          return <tr key={index} className={classes.lines}>
            <td>
              <div className={classes.projectContainer} style={{ color: projectStatus[project.status] }}>
                <span className={classes.dot} style={{ background: projectStatus[project.status] }} />
                <span>{getValue(window, ['_DATA', 'types', 'project_status', project.status, 'label'])}</span>
              </div>
            </td>
            <td><span>{project.name}</span></td>
            <td>
              <span className={classes.projectPrice}>{`Prix ${project.price}€`}</span>
            </td>
            <td>{Boolean(project.maintenance) && <span className={classes.projectMaintenance}>{`Maintenance ${project.maintenance}€ /an`}</span>}</td>
            <td><span>{formatDate({ time: project.time_end, display: 'date' })}</span></td>
            <td>
              <div className={classes.actionsContainer}>
                <Tooltip title="Détail du projet">
                  <Link to={`/gestion/projets/${project.project_id}`} className={classes.actionIconsContainer}>
                    <SearchIcon />
                  </Link>
                </Tooltip>
                <Tooltip title="Éditer">
                  <Link to={`/gestion/projets/${project.project_id}/editer`} className={classes.actionIconsContainer}>
                    <EditIcon />
                  </Link>
                </Tooltip>
              </div>
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </>
};

export default ProjectsList;