
// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss'

// 
// ─── IMPORT DES COMPOSANTS ───────────────────────────────────────
//
import changelog from '../configs/changelog.json'

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const Changelog = () => {

  let tags = {
    "new": "#3fcd3c",
    "improved": "#4cb3d3",
    "fixed": "#cf4747",
    "announced": "#cf45c1"
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    card: {
      padding: 20,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      marginBottom: 20,
    },
    header: {
      marginBottom: "20px"
    },
    title: {
      margin: "20px 0 0",
      color: 'var(--text-color)',
    },
    author: {
      fontSize: "14px",
      fontWeight: "bold",
      color: 'var(--text-color)',
    },
    content: {
      display: "flex",
      marginTop: "10px",
      color: 'var(--text-color)',
    },
    tag: {
      color: "#FFF",
      padding: "0 10px",
      fontSize: "12px",
      height: "20px",
      marginRight: "20px",
      width: "55px",
      textAlign: "center",
      borderRadius: "5px"
    }
  })

  const classes = useStyle()

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return (
    <>
      {[...changelog].reverse().map((value) => (
        value.status === 'released' && <div key={value.id} className={classes.card}>
          <div className={classes.header}>
            <h2 className={classes.title}>{`${value.version} - ${value.date}`}</h2>
            <span className={classes.author}>{value.author}</span>
          </div>
          <div>
            {value.features.map((val, index) => (
              <div key={index} className={classes.content}>
                <span className={classes.tag} style={{ background: tags[val.tag] }}>{val.tag}</span>
                <div>
                  <span>{val.title}</span>
                  {Boolean(val.elements.length) && (
                    <ul>
                      {val.elements.map((el, i) => (
                        <li key={i}>{el.description}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  )
};

export default Changelog;