// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClientManage from '../../containers/ClientManage'
import Api from '../../contexts/Api'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ClientEdit = () => {
  const clientParams = useParams()
  const [client, setClient] = useState()

  // 
  // ─── GET CLIENTS TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/clients',
        query: {
          username: clientParams.username
        },
        method: "GET"
      }

      const getClients = await Api(params)
      if (getClients.success) {
        setClient(getClients.data)
      }
    }
    if (!client) {
      fetchData()
    }
  }, [client, clientParams])

  return <>
    {client && <ClientManage defaults={client} update={true} />}
  </>
};

export default ClientEdit;