// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import KilometricAllowanceManage from '../../containers/KilometricAllowanceManage';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const KilometricAllowanceAdd = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <KilometricAllowanceManage />
};

export default KilometricAllowanceAdd;