// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import StorageIcon from '@mui/icons-material/Storage';
import MemoryIcon from '@mui/icons-material/Memory';
import Api from '../../contexts/Api';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Monitoring = () => {

  const [servers, setServers] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: "/server_monitoring",
        method: "GET"
      }
      const getServeurs = await Api(params)
      if (getServeurs.success) {
        setServers(getServeurs.data)
      }
    }
    if (!servers.length) { fetchData() }

  }, [servers])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      color: 'var(--text-color)'
    },
    cardContainer: {
      marginBottom: 40
    },
    cardHeader: {
      display: 'flex',
      marginBottom: 10,
      gap: 40
    },
    headerTitle: {
      display: 'flex',
      alignItems: 'center',
      '&>svg': {
        marginRight: 6,
        fill: 'var(--colored-element-color)'
      }
    },
    headerRight: {
      marginLeft: 'auto'
    },
    card: {
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: '15px 20px',
      display: 'flex',
      justifyContent: 'space-between'
    },
    cardItems: {
      display: 'flex',
      gap: 40
    },
    cartItem: {
      display: 'flex',
      flexDirection: 'column'
    },
    cartItemTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 4,
      '&>svg': {
        marginRight: 6,
      }
    },
    cartItemValue: {
      fontSize: 30
    },
    infos: {
      fontSize: 12,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    version: {
      fontWeight: 'bold',
      '& span:nth-child(1)': {
        marginRight: 5,
      },
      '& span:nth-child(2)': {
        color: 'var(--colored-element-color)'
      }
    }
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Boolean(servers.length) && <div className={classes.container}>
    {servers.map((server) => <div className={classes.cardContainer}>
      <div className={classes.cardHeader}>
        <span className={classes.headerTitle}>
          <StorageIcon />
          {server.name}
        </span>
        <span className={classes.headerTitle}>
          <svg xmlns="http://www.w3.org/2000/svg" fill='var(--text-color)' width='16px' height='16px' viewBox="0 0 512 512"><path d="M416 48v416c0 26.51-21.49 48-48 48H144c-26.51 0-48-21.49-48-48V48c0-26.51 21.49-48 48-48h224c26.51 0 48 21.49 48 48zm96 58v12a6 6 0 0 1-6 6h-18v6a6 6 0 0 1-6 6h-42V88h42a6 6 0 0 1 6 6v6h18a6 6 0 0 1 6 6zm0 96v12a6 6 0 0 1-6 6h-18v6a6 6 0 0 1-6 6h-42v-48h42a6 6 0 0 1 6 6v6h18a6 6 0 0 1 6 6zm0 96v12a6 6 0 0 1-6 6h-18v6a6 6 0 0 1-6 6h-42v-48h42a6 6 0 0 1 6 6v6h18a6 6 0 0 1 6 6zm0 96v12a6 6 0 0 1-6 6h-18v6a6 6 0 0 1-6 6h-42v-48h42a6 6 0 0 1 6 6v6h18a6 6 0 0 1 6 6zM30 376h42v48H30a6 6 0 0 1-6-6v-6H6a6 6 0 0 1-6-6v-12a6 6 0 0 1 6-6h18v-6a6 6 0 0 1 6-6zm0-96h42v48H30a6 6 0 0 1-6-6v-6H6a6 6 0 0 1-6-6v-12a6 6 0 0 1 6-6h18v-6a6 6 0 0 1 6-6zm0-96h42v48H30a6 6 0 0 1-6-6v-6H6a6 6 0 0 1-6-6v-12a6 6 0 0 1 6-6h18v-6a6 6 0 0 1 6-6zm0-96h42v48H30a6 6 0 0 1-6-6v-6H6a6 6 0 0 1-6-6v-12a6 6 0 0 1 6-6h18v-6a6 6 0 0 1 6-6z" /></svg>
          <span className={classes.system}>{`${server.usage} - ${server.type}`}</span>
        </span>
        <span className={classes.headerTitle}>
          <svg xmlns="http://www.w3.org/2000/svg" fill='var(--text-color)' width='16px' height='16px' viewBox="0 0 448 512"><path d="M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z" /></svg>
          {server.memory.total}
        </span>
        <span className={classes.headerTitle}>
          <svg xmlns="http://www.w3.org/2000/svg" fill='var(--text-color)' width='16px' height='16px' viewBox="0 0 576 512"><path d="M576 304v96c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48v-96c0-26.51 21.49-48 48-48h480c26.51 0 48 21.49 48 48zm-48-80a79.557 79.557 0 0 1 30.777 6.165L462.25 85.374A48.003 48.003 0 0 0 422.311 64H153.689a48 48 0 0 0-39.938 21.374L17.223 230.165A79.557 79.557 0 0 1 48 224h480zm-48 96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm-96 0c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z" /></svg>
          {server.storage.total}
        </span>
        <span className={classes.headerRight}>{server.ip}</span>
      </div>
      <div className={classes.card}>
        <div className={classes.cardItems}>
          <div className={classes.cartItem}>
            <div className={classes.cartItemTitle}>
              <MemoryIcon />
              <span>CPU</span>
            </div>
            <span className={classes.cartItemValue}>{server.load}</span>
          </div>
          <div className={classes.cartItem}>
            <div className={classes.cartItemTitle}>
              <svg xmlns="http://www.w3.org/2000/svg" fill='var(--text-color)' width='16px' height='16px' viewBox="0 0 448 512"><path d="M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z" /></svg>
              <span>Mémoire</span>
            </div>
            <span className={classes.cartItemValue}>{server.memory.usage}</span>
          </div>
          <div className={classes.cartItem}>
            <div className={classes.cartItemTitle}>
              <svg xmlns="http://www.w3.org/2000/svg" fill='var(--text-color)' width='16px' height='16px' viewBox="0 0 576 512"><path d="M576 304v96c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48v-96c0-26.51 21.49-48 48-48h480c26.51 0 48 21.49 48 48zm-48-80a79.557 79.557 0 0 1 30.777 6.165L462.25 85.374A48.003 48.003 0 0 0 422.311 64H153.689a48 48 0 0 0-39.938 21.374L17.223 230.165A79.557 79.557 0 0 1 48 224h480zm-48 96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm-96 0c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z" /></svg>
              <span>Disque dur</span>
            </div>
            <span className={classes.cartItemValue}>{server.storage.usage}</span>
          </div>
        </div>
        <div className={classes.infos}>
          <div className={classes.versions}>
            <div className={classes.version}>
              <span>PHP</span>
              <span>{server.versions.php}</span>
            </div>
            <div className={classes.version}>
              <span>MySQL</span>
              <span>{server.versions.mysql}</span>
            </div>
          </div>
          <div className={classes.uptime}>{`En ligne depuis ${server.uptime}`}</div>
        </div>
      </div>
    </div>)}
  </div>
};

export default Monitoring;