// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Api from '../contexts/Api';
import Formfields from "../components/Formfields";
import { prepareFormData, getValue, formatTime } from '../contexts/Utils';
import { useNotification } from '../components/UseNotification';
import ArrowBack from '../components/ArrowBack';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const ProjectManage = (props) => {
  const { notification } = useNotification();
  let navigate = useNavigate();
  let defaults = props.defaults || {};

  const cookies = new Cookies();
  const userData = cookies.get('olwe_management_user') || {};

  const [errors, setErrors] = useState({});
  const [projects, setProjects] = useState([]);
  const [holiday, setholiday] = useState(null);
  const [public_holiday, setPublicHoliday] = useState(null);
  const [allDay, setAllDay] = useState(false)

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'myForm' });

    if (data.errors) {
      setErrors(data.errors)
    } else {
      if (props.update) {
        data.time_tracking_id = defaults.time_tracking_id
      }
      if (!props.update) {
        data.fields.user_id = userData.user_id
      }

      if (data.fields.holiday) {
        data.fields.project_id = ''
      }

      const params = {
        data,
        endpoint: '/time_tracking',
        method: props.update ? 'PUT' : 'POST'
      }

      const createTimeTracking = await Api(params)
      notification({ variant: createTimeTracking.success ? 'success' : 'error', message: createTimeTracking.message });
      setErrors({});

      if (createTimeTracking.success) {
        return navigate("/gestion/mes-temps");
      }
    }
  }

  // 
  // ─── SET DE LA JOURNÉE AU LIEU DES HEURES ───────────────────────────────────────
  //
  const handleAllDay = () => {
    setAllDay(!allDay)
  }

  // 
  // ─── RÉCPÉRATION DES USERNAME/ID DES CLIENTS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/projects',
        query: {
          fields: 'project_id,projects.name'
        },
        method: "GET"
      }
      const getProjects = await Api(params)
      if (getProjects.success) {
        setProjects(getProjects.data);
      }
    }
    if (!projects.length) { fetchData() }

  }, [projects])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      gap: 20
    },
    card: {
      flex: 1,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 20,
      borderRadius: 20,
      '&>div:not(:first-child)': {
        marginTop: 20
      }
    },
    submit: {
      margin: '20px auto 10px !important'
    }
  })
  const classes = useStyle();

  const checkHolidays = ((!defaults.holiday && (holiday === 0 || holiday == null)) || (defaults.holiday && holiday === 0)) && ((!defaults.public_holiday && (public_holiday === 0 || public_holiday == null)) || (defaults.public_holiday && public_holiday === 0));
  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    {
      fields: [
        { type: "radio", component: "switch", name: "all_day", label: "Toute la journée", default: defaults.all_day, value: 0, handleChange: handleAllDay },
        { type: 'date', component: "datepicker", name: "date_time", label: "Date", required: true, default: defaults.date_time },
        { component: "text", name: "duration", label: 'Durée', placeholder: '00:00', default: allDay ? formatTime(60 * 60 * 7) : defaults.duration, required: true },
        { type: "radio", component: "switch", name: "holiday", label: "Congés", default: defaults.holiday, value: 0, handleChange: (e) => setholiday(e), cond: (!defaults.public_holiday && (public_holiday === 0 || public_holiday == null)) || (defaults.public_holiday && public_holiday === 0) },
        { type: "radio", component: "switch", name: "public_holiday", label: "Jour férié", default: defaults.public_holiday, value: 0, handleChange: (e) => setPublicHoliday(e), cond: (!defaults.holiday && (holiday === 0 || holiday == null)) },
        { type: "select", component: "select", name: "project_id", label: "Projet", data: projects, default: defaults.project_id || '', dataLabel: 'name', dataValue: "project_id", cond: checkHolidays },
        {
          type: "select", component: "select", name: "activity", label: "Activité", dataLabel: 'name', dataValue: 'id', default: defaults.offer_id || '', data: [
            { id: 1, name: 'Définiton' },
            { id: 2, name: "Rédaction d'un cahier des charges" },
            { id: 3, name: 'Développement' },
            { id: 4, name: 'Maquettage' },
            { id: 5, name: 'Infographie' },
            { id: 6, name: 'Devis' },
            { id: 7, name: 'Prospection' },
            { id: 8, name: 'Rendez-vous' },
          ],
          cond: checkHolidays
        },
        { component: "text", name: "name", label: 'Libellé', required: true },
        { component: "text", name: "description", label: 'Description', multiline: true, rows: 4, cond: checkHolidays },
        { type: "radio", component: "switch", name: "billable", label: "Facturable", default: defaults.billable, value: 0, cond: checkHolidays },
      ]
    }
  ]

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <div>
    <ArrowBack link='/gestion/mes-temps' />
    <form onSubmit={handleChange} className={classes.form} name="myForm">
      <div className={classes.container}>
        {formFields.map((element, index) => {
          return <div key={index} className={classes.card}>
            {element.fields.map((el) => {
              if (el.hasOwnProperty('cond') && !el.cond) return true
              if (defaults[el.name] && !el.default) el.default = defaults[el.name]

              // 
              // ─── DISPLAY FIELDS ───────────────────────────────────────
              //
              return <Formfields key={el.name} field={{ ...el, error: getValue(errors, [el.name, 'message']) }} slug={props.slug} />
            })}
          </div>
        })}
      </div>
      <Button variant="contained" className={classes.submit} onClick={handleChange}>Enregistrer</Button>
    </form>
  </div >
};

export default ProjectManage;