// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss'
import Api from '../../contexts/Api'
import { formatDate, getValue } from '../../contexts/Utils';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ClientsList = (props) => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [clients, setClients] = useState({ list: [], filtred: [] });

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/clients',
        method: "GET"
      }

      const getClients = await Api(params)
      if (getClients.success) {
        setClients({ list: getClients.data, filtred: [] })
      }
    }
    if (!clients.list.length) {
      fetchData()
    }
  }, [clients])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    title: {
      color: 'var(--text-color)',
      margin: '0 auto 40px'
    },
    cardContainer: {
      marginBottom: 40,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      '&>div:not(:last-child)': {
        borderBottom: '1px solid var(--divider-color)'
      }
    },
    actionsContainer: {
      display: 'flex',
    },
    actionIconsContainer: {
      width: 20,
      height: 20,
      marginLeft: 5,
      color: 'var(--text-color)',
      '&>svg': {
        width: '20px'
      }
    },
    clientContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    dot: {
      minWidth: 6,
      minHeight: 6,
      borderRadius: '100%',
      marginRight: 8
    },
    clientStatus: {
      padding: '3px 6px',
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      padding: 10,
      marginBottom: 40
    },
    lines: {
      alignItems: 'center',
      color: 'var(--text-color)',
      fontSize: 14,
      borderBottom: '1px solid var(--divider-color)',
      '&>td': {
        padding: '14px 10px'
      }
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 10,
      color: 'var(--subtitle-color)'
    },
    filtersBtn: {
      display: 'flex',
      gap: 40,
      cursor: 'pointer',
      '& > div:hover ': {
        color: 'var(--secondary-color)',
      }
    },
    filtersSearch: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    filtersSearchInput: {
      background: 'none',
      border: '1px solid var(--divider-color)',
      padding: 5,
      width: 240,
      color: 'var(--text-color)',
      outline: 'none'
    }
  })

  const handleSearch = (e) => {
    const value = e.target.value;
    const tmpClients = clients.list;
    const tmpList = tmpClients.filter(element => element.name.toLowerCase().includes(value) || element.firstname.toLowerCase().includes(value) || element.username.includes(value));

    setClients({ ...clients, filtred: tmpList })
  }

  const classes = useStyle()

  const clientStatus = {
    1: { color: '#c1801e', background: '#ffe8cb' },
    2: { color: '#229a16', background: '#e4f8dd' },
    3: { color: '#bd0000', background: '#ffcbcb' },
  }

  let clientDisplay = clients.list;

  if (clients.filtred.length) {
    clientDisplay = clients.filtred
  }

  return <div>
    <h1 className={classes.title}>Liste des clients</h1>
    <div className={classes.filters}>
      <div className={classes.filtersBtn}>
        <div onClick={() => setClients({ list: clients.list, filtred: [] })}>Tous les clients</div>
        <div onClick={() => setClients({ list: clients.list, filtred: clients.list.filter(element => element.status === 1) })}>Prospects</div>
        <div onClick={() => setClients({ list: clients.list, filtred: clients.list.filter(element => element.status === 2) })}>Clients</div>
        <div onClick={() => setClients({ list: clients.list, filtred: clients.list.filter(element => element.status === 3) })}>Anciens clients</div>
      </div>
      <div className={classes.filtersSearch}>
        <input type="text" className={classes.filtersSearchInput} onChange={(e) => handleSearch(e)} />
        <SearchIcon />
      </div>
    </div>
    <table className={classes.table}>
      <tbody className={classes.cardContainer}>
        {clientDisplay.map((client, index) => {
          return <tr key={index} className={classes.lines}>
            <td>
              <span style={{ color: clientStatus[client.status].color, background: clientStatus[client.status].background }} className={classes.clientStatus}>{getValue(window, ['_DATA', 'types', 'client_status', client.status, 'label'])}</span>
            </td>
            <td><span>{`${client.name} ${client.firstname}`}</span></td>
            <td>
              <div className={classes.clientContainer} style={{ color: '#FFB100' }}>
                <span className={classes.dot} style={{ background: '#FFB100' }} />
                <span>{client.username}</span>
              </div>
            </td>
            <td> <span>{client.phone}</span></td>
            <td><span>{client.email}</span></td>
            <td><span>{formatDate({ time: client.time_create, display: 'date' })}</span></td>
            <td>
              <div className={classes.actionsContainer}>
                <Tooltip title="Fiche client">
                  <Link to={`/gestion/clients/${client.username}/fiche`} className={classes.actionIconsContainer}>
                    <SearchIcon />
                  </Link>
                </Tooltip>
                <Tooltip title="Éditer">
                  <Link to={`/gestion/clients/${client.username}/editer`} className={classes.actionIconsContainer}>
                    <EditIcon />
                  </Link>
                </Tooltip>
              </div>
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
};

export default ClientsList;