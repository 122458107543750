// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import LineChart from '../components/Charts/LineChart';
import Api from '../contexts/Api';
import { formatData } from '../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Analytics = () => {

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [analytics, setAnalytics] = useState({});

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/internal_analytics',
        method: "GET"
      }

      const getAnalytics = await Api(params)
      if (getAnalytics.success) {
        setAnalytics(getAnalytics.data)
      }
    }
    if (!Object.keys(analytics).length) {
      fetchData()
    }
  }, [analytics])


  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 40,
      marginBottom: 40
    },
    outerContainer: {
    },
    container: {
      display: 'flex',
      gap: 20
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      color: 'var(--text-color)',
      width: '100%'
    },
    category: {
      color: 'var(--text-color)'
    },
    cardTitle: {
      marginBottom: 10,
      fontWeight: 'bold'
    },
    timesContainer: {
      display: 'flex',
      gap: 20,
      width: '100%'
    },
    stats: {
      padding: 20,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      display: 'flex',
      flexDirection: 'column',
      color: 'var(--text-color)',
      height: 277,
      overflowY: 'auto'
    },
    statsTitle: {
      marginBottom: 5,
      display: 'block'
    },
    progressContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10
    },
    progress: {
      width: '100%',
      height: 6,
      background: 'var(--progress-color)',
      position: 'relative',
    },
    progressActive: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      background: 'var(--colored-element-color)',
      width: 50
    },
    progressNumber: {
      marginLeft: 20,
      padding: "2px 8px",
      background: 'var(--colored-element-color)',
      fontSize: 14,
      borderRadius: 4,
      color: '#fff'
    },
    chart: {
      width: 'calc(100% - 40px)'
    }
  })
  const classes = useStyle();

  let categories = {
    'time_tracking': 'Heures de travail',
    'projects': 'Projets réalisés'
  }

  let titles = {
    'week': 'Semaine',
    'month': 'Mois',
    'year': 'Année',
    'total': 'Total',
    'projects': 'Projets'
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Boolean(Object.keys(analytics).length) && <div className={classes.wrapper}>
    <div className={classes.outerContainer}>
      <h2 className={classes.category}>{categories['time_tracking']}</h2>
      <div className={classes.container}>
        <div className={classes.timesContainer}>
          {Object.entries(analytics.time_tracking.times).map(([k, v]) => {
            return <div className={classes.card}>
              <span className={classes.cardTitle}>{titles[k]}</span>
              <span>{formatData(v)}</span>
            </div>
          })}
        </div>
      </div>
    </div>

    <div className={classes.outerContainer}>
      <h2 className={classes.category}>{categories['projects']}</h2>
      <div className={classes.container}>
        <div className={classes.stats} style={{ width: 'calc(50% - 40px)' }}>
          <h3 style={{ marginTop: 10 }}>Les plus vendus</h3>
          {((Object.values(analytics.projects.stats)).sort((a, b) => a.number - b.number)).reverse().map((value, index) => {
            return <div>
              <span className={classes.statsTitle}>{`#${index + 1} ${value.name}`}</span>
              <div className={classes.progressContainer}>
                <div className={classes.progress}>
                  <div className={classes.progressActive} style={{ width: `${value.percent}%` }} />
                </div>
                <div className={classes.progressNumber}>{`${value.percent.toFixed(2)}%`}</div>
              </div>
            </div>
          })}
        </div>
        <div className={classes.timesContainer} style={{ width: '50%', flexWrap: 'wrap' }}>
          {Object.entries(analytics.projects.times).map(([k, v]) => {
            return <div className={classes.card} style={{ width: 'calc(50% - 50px)' }}>
              <span className={classes.cardTitle}>{titles[k]}</span>
              <span>{formatData(v)}</span>
            </div>
          })}
        </div>
      </div>
    </div>
    <div className={classes.card + ' ' + classes.chart}>
      <LineChart data={analytics.projects.sales} />
    </div>
  </div>
};

export default Analytics;