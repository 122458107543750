// 
// ─── IMPORT DES ELEMENTS NECESSAIRESS ───────────────────────────────────────
//
import React from 'react';
import InventoryManage from '../../containers/InventoryManage';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const InventoryAdd = () => {
  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <InventoryManage />
};

export default InventoryAdd;