// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Base64Converter = () => {

  const [encodeBase64, setEncodeBase64] = useState("");
  const [decodeBase64, setDecodeBase64] = useState("");

  // 
  // ─── ENCODE AND DECODE STRING TO BASE64 ───────────────────────────────────────
  //
  const base64Encode = (e) => {
    setEncodeBase64(btoa(e.target.value));
  }

  const base64Decode = (e) => {
    setDecodeBase64(atob(e.target.value));
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      color: 'var(--text-color)',
      maxWidth: 900,
      margin: 'auto',
    },
    container: {
      display: 'flex',
      gap: 20
    },
    block: {
      width: '50%',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      borderColor: 'transparent',
      padding: 20,
      background: 'var(--block-color)',
    },
    textarea: {
      width: 'calc(100% - 20px)',
      padding: '5px 10px',
      color: 'var(--text-color)',
      outline: 'none',
      fontFamily: 'Open-sans, sans-serif',
      lineHeight: 1.5,
      background: 'var(--block-color)',
      border: '1px solid var(--colored-element-color)',
      '&:focus': {
        border: '1px solid var(--colored-element-color)',
        background: 'rgba(255, 177, 0,0.2)'
      }
    },
    formContainer: {
      '&:nth-child(2)': {
        marginBottom: 10,
      },
      "&>span": {
        display: 'block',
        marginBottom: 10
      }
    },
    formTitle: {
      marginTop: 0
    }
  })

  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    <div className='tools-header'>
      <h1>Base64 string encoder/decoder</h1>
      <p>Il suffit de coder et de décoder une chaîne de caractères dans sa représentation base64.</p>
    </div>
    <div className={classes.container}>
      <div className={classes.block}>
        <h2 className={classes.formTitle}>String to base64</h2>
        <div className={classes.formContainer}>
          <span>String to encode</span>
          <textarea placeholder='Mettez votre chaîne ici...' className={classes.textarea} rows='3' onChange={base64Encode}></textarea>
        </div>
        <div className={classes.formContainer}>
          <span>Base64 of string</span>
          <textarea placeholder="L'encodage base64 de votre chaîne sera ici" className={classes.textarea} rows='3' value={encodeBase64} readOnly></textarea>
        </div>
      </div>
      <div className={classes.block}>
        <h2 className={classes.formTitle}>Base64 to string</h2>
        <div className={classes.formContainer}>
          <span>Base64 string to decode</span>
          <textarea placeholder='Votre chaîne en base64...' className={classes.textarea} rows='3' onChange={base64Decode}></textarea>
        </div>
        <div className={classes.formContainer}>
          <span>Decoded string</span>
          <textarea placeholder="La chaîne décodée sera ici" className={classes.textarea} rows='3' value={decodeBase64} readOnly></textarea>
        </div>
      </div>
    </div>
  </div >
};

export default Base64Converter;