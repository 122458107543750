// 
// ─── IMPORT NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import OfferManage from '../../containers/OfferManage';
import Api from '../../contexts/Api'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const OfferEdit = () => {
  const offerParams = useParams()
  const [offer, setOffer] = useState()

  // 
  // ─── GET OFFERS ELEMENT TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/offers',
        query: {
          offer_id: offerParams.offer_id
        },
        method: "GET"
      }

      const getOffer = await Api(params)
      if (getOffer.success) {
        setOffer(getOffer.data)
      }
    }
    if (!offer) {
      fetchData()
    }
  }, [offer, offerParams])

  return <>
    {offer && <OfferManage defaults={offer} update={true} />}
  </>
};

export default OfferEdit;