// 
// ─── IMPORT DES ELEMENTS NECESSAIRES ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Api from '../contexts/Api';
import Formfields from "../components/Formfields";
import { prepareFormData, getValue } from '../contexts/Utils';
import Button from '@mui/material/Button';
import { useNotification } from '../components/UseNotification';
import ArrowBack from '../components/ArrowBack';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const KilometricAllowanceManage = (props) => {
  const { notification } = useNotification();
  let navigate = useNavigate();
  let defaults = props.defaults || {};

  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);
  const [price, setPrice] = useState(0);

  // 
  // ─── VEHICLE POWERS ───────────────────────────────────────
  //
  const vehiclePower = {
    1: 0.502,
    2: 0.575,
    3: 0.603,
    4: 0.631,
    5: 0.661
  }

  // 
  // ─── GET CLIENTS VALUES FOR USENAME ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        endpoint: '/clients',
        query: {
          fields: 'username,client_id'
        },
        method: "GET"
      }
      const getKilometricAllowance = await Api(params)
      if (getKilometricAllowance.success) {
        setClients(getKilometricAllowance.data)
      }
    }
    if (!clients.length) { fetchData() }

  }, [clients])

  // 
  // ─── HANDLE FORM SUBMIT ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'myForm' });

    if (data.errors) {
      setErrors(data.errors)
    } else {

      if (props.update) {
        data.kilometric_allowance_id = defaults.kilometric_allowance_id
      }

      data.fields.price = price;

      const params = {
        data,
        endpoint: '/kilometric_allowance',
        method: props.update ? 'PUT' : 'POST'
      }

      const createKilometricAllowance = await Api(params)
      notification({ variant: createKilometricAllowance.success ? 'success' : 'error', message: createKilometricAllowance.message });

      if (createKilometricAllowance.success) {
        return navigate("/gestion/indemnites-kilometriques");
      }
    }
  }

  // 
  // ─── HANDLE PRICE CALCULATE ───────────────────────────────────────
  //
  const handlePrice = () => {
    let data = prepareFormData({ formId: 'myForm' });

    if (data.errors) {
      setErrors(data.errors)
    } else {
      setPrice(Math.round(parseFloat(data.fields.distance) * (vehiclePower[parseInt(data.fields.vehicle_power)])))
    }
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      gap: 20
    },
    card: {
      flex: 1,
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 20,
      borderRadius: 20,
      '&>div:not(:first-child)': {
        marginTop: 20
      }
    },
    btnContainer: {
      display: 'flex',
      gap: 20,
      marginTop: 20
    },
    price: {
      color: 'var(--text-color)'
    }
  })
  const classes = useStyle();

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    {
      fields: [
        { type: "select", component: "select", name: "client_id", label: "Username du client", data: clients, default: defaults.client_id || '-', dataLabel: 'username', dataValue: "client_id" },
        {
          type: "select", component: "select", name: "vehicle", label: "Type de véhicule", dataLabel: 'name', dataValue: 'id', default: defaults.vehicle || 1, data: Object.values(window._DATA.types['vehicle']).map((type) => {
            return { id: type.value, name: type.label }
          }),
        },
        {
          type: "select", component: "select", name: "vehicle_power", label: "Puissance du véhicule", dataLabel: 'name', dataValue: 'id', default: defaults.vehicle_power || 5, data: Object.values(window._DATA.types['vehicle_power']).map((type) => {
            return { id: type.value, name: type.label }
          }),
        },
        { component: "text", required: true, name: "distance", label: "Distance" },
        { component: "text", multiline: true, rows: 4, name: "description", label: "Description" },
        { type: 'date', component: "datepicker", name: "time_travel", label: "Date du trajet" },
      ]
    }
  ]

  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <div>
    <ArrowBack link='/gestion/indemnites-kilometriques' />
    <form onSubmit={handleChange} className={classes.form} name="myForm">
      <div className={classes.container}>
        {formFields.map((element, index) => {
          return <div key={index} className={classes.card}>
            {element.fields.map((el) => {
              if (el.hasOwnProperty('cond') && !el.cond) return true
              if (defaults[el.name] && !el.default) el.default = defaults[el.name]

              // 
              // ─── DISPLAY FIELDS ───────────────────────────────────────
              //
              return <Formfields key={el.name} field={{ ...el, error: getValue(errors, [el.name, 'message']) }} slug={props.slug} />
            })}
            {Boolean(price) && <div className={classes.price}>{`Prix : ${price}€`}</div>}
          </div>
        })}
      </div>
      <div className={classes.btnContainer}>
        <Button variant="contained" className={classes.submit} onClick={handlePrice}>Calculer le prix</Button>
        {Boolean(price) && <Button variant="contained" className={classes.submit} onClick={handleChange}>Enregistrer</Button>}
      </div>
    </form>
  </div >
};

export default KilometricAllowanceManage;