// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Formfields from "../../components/Formfields";
import { prepareFormData } from '../../contexts/Utils';
import Button from '@mui/material/Button';
import { useNotification } from '../../components/UseNotification';
import Api from '../../contexts/Api';
import { getValue } from '../../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const WebsiteCheckList = () => {
  const websiteParams = useParams();
  const { notification } = useNotification();
  let navigate = useNavigate();

  const [website, setWebsite] = useState();

  // 
  // ─── GET WEBSITE TO EDIT ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchWebsite = async () => {

      const getWebsites = await Api({
        endpoint: '/websites',
        query: {
          domain: websiteParams.domain
        },
        method: "GET"
      })
      if (getWebsites.success) {
        setWebsite(getWebsites.content)
      }
    }
    if (!website) {
      fetchWebsite()
    }

  }, [website, websiteParams])

  // 
  // ─── SAVE WEBSITE CHECKLIST ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'myForm' });
    let fieldsData = JSON.stringify(data.fields)

    const params = {
      data: {
        fields: { checklist: fieldsData, domain: websiteParams.domain },
      },
      endpoint: '/websites',
      method: 'PUT'
    }

    const updateWebsite = await Api(params)
    notification({ variant: updateWebsite.success ? 'success' : 'error', message: updateWebsite.message })

    if (updateWebsite.success) {
      return navigate("/gestion/sites");
    }
  }

  // 
  // ─── FIELDS DECLARATION ───────────────────────────────────────
  //
  const fields = [
    {
      title: 'Avant la mise en ligne',
      fields: [
        { component: "checkbox", name: "orthography", label: "Orthographe sur l'ensemble du site" },
        { component: "checkbox", name: "remove_lorem_ipsum", label: "Retirer tout le lorem ipsum" },
        { component: "checkbox", name: "image_alt", label: "alt texte sur toutes les images" },
        { component: "checkbox", name: "image_optimisation", label: "Optimisation des images" },
        { component: "checkbox", name: "image_format", label: "Images dans les formats optimisés (webp)" },
        { component: "checkbox", name: "medias_working", label: "Toutes les images/vidéos et fichiers audio fonctionnent" },
        { component: "checkbox", name: "meta_title", label: "Balises titres correctement renseignées" },
        { component: "checkbox", name: "meta_description", label: "Meta description de toutes les pages" },
        { component: "checkbox", name: "title_tag", label: "Hierarchie des balises titre (h1, h2...)" },
        { component: "checkbox", name: "responsive", label: "Toutes les pages sont responsive" },
        { component: "checkbox", name: "browser_check", label: "Vérifier les différents navigateurs" },
        { component: "checkbox", name: "page_404", label: "Vérifier que la page 404 fonctionne" },
        { component: "checkbox", name: "broken_links", label: "Tous les liens mènent vers la bonne page" },
        { component: "checkbox", name: "favicon", label: "Le site a un favicon" },
        { component: "checkbox", name: "call_to_action_working", label: "Tous les boutons fonctionnent" },
        { component: "checkbox", name: "website_performances", label: "Performances du site" },
        { component: "checkbox", name: "privacy_policy", label: "Politique de confidentialité" },
        { component: "checkbox", name: "legals", label: "Mentions légales" },
        { component: "checkbox", name: "recaptcha", label: "Ajout d'un reCAPTCHA si formulaire de contact" },
        { component: "checkbox", name: "ssl", label: "Le site est sous SSL" },
        { component: "checkbox", name: "url_rewrite", label: "Réécriture des urls" },
        { component: "checkbox", name: "webiste_indexation", label: "Indexation du site" },
        { component: "checkbox", name: "image_licence", label: "Si l'image n'est pas libre de droit, la licence est présente" },
        { component: "checkbox", name: "mails_sent_before", label: "L'envoie de mail fonctionne" },
        { component: "checkbox", name: "lighthouse_before", label: "Lighthouse sur l'ensemble des pages" },
        { component: "checkbox", name: "sitemap_before", label: "Sitemap.xml" },
      ],
    },
    {
      title: 'Après la mise en ligne',
      fields: [
        { component: "checkbox", name: "google_analytics", label: "Google analytics" },
        { component: "checkbox", name: "google_search_console", label: "Google Search Console" },
        { component: "checkbox", name: "cloudflare", label: "Cloudflare" },
        { component: "checkbox", name: "mail_sent_after", label: "L'envoie de mail fonctionne" },
        { component: "checkbox", name: "lighthouse_after", label: "Lighthouse sur l'ensemble des pages" },
        { component: "checkbox", name: "sitemap_after", label: "Sitemap.xml" },
      ],

    }
  ]

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      marginBottom: 40
    },
    container: {
      display: 'flex',
      gap: 20
    },
    card: {
      display: 'flex',
      flexWrap: 'wrap',
      background: 'var(--block-color)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      padding: 20,
      borderRadius: 20,
      '&>div': {
        width: '50% !important'
      }
    },
    submit: {
      margin: '20px auto 40px !important'
    },
  })
  const classes = useStyle();

  let checklist = {};
  if (getValue(website, ['checklist'])) {
    checklist = JSON.parse(website.checklist);
  }
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return website && <div>
    <form name="myForm">
      {fields.map((value) => {
        return <div key={value.title}>
          <div className='tools-header'>
            <h2>{value.title}</h2>
          </div>
          <div className={classes.card}>
            {value.fields.map((field) => {
              return <Formfields key={field.name} field={{ ...field, checked: checklist[field.name] }} />
            })}
          </div>
        </div>
      })}
    </form>
    <Button variant="contained" className={classes.submit} onClick={handleChange}>Valider la checklist</Button>
  </div>
};

export default WebsiteCheckList;