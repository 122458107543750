// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import QuoteManage from "../../containers/QuoteManage";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const QuoteAdd = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <QuoteManage />
};

export default QuoteAdd;