// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import ColorPicker from '../../components/ColorPicker';
import Formfields from '../../components/Formfields';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const QrCode = () => {

  const [bgColor, setBgColor] = useState("#FFF");
  const [fgColor, setFgColor] = useState("#000");
  const defaultUrl = "https://olwe.fr/";
  const [url, setUrl] = useState(defaultUrl);
  const defaultImage = "https://olwe.fr/favicon.png";
  const [image, setImage] = useState(defaultImage);
  const [addImage, setAddImage] = useState(1)
  const [difficult, setDifficult] = useState('L')

  // 
  // ─── RECUPERATION DES COULEURS ───────────────────────────────────────
  //
  const getBgColor = (opt) => {
    setBgColor(opt.color)
  }
  const getFgColor = (opt) => {
    setFgColor(opt.color)
  }

  // 
  // ─── SET DE L'IMAGE ───────────────────────────────────────
  //
  const handleImage = (e) => {
    setImage(e || defaultImage)
  }

  // 
  // ─── PERMETTRE L'AJOUT D'IMAGES ───────────────────────────────────────
  //
  const handleAddImage = (e) => {
    setAddImage(e)
  }

  // 
  // ─── PERMETTRE L'AJOUT D'IMAGES ───────────────────────────────────────
  //
  const handleUrl = (e) => {
    setUrl(e.target.value || defaultUrl);
  }

  // 
  // ─── PERMETTRE L'AJOUT D'IMAGES ───────────────────────────────────────
  //
  const handleDifficult = (e) => {
    setDifficult(e);
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      color: 'var(--text-color)',
      maxWidth: 900,
      margin: 'auto',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,

    },
    block: {
      width: 'calc(100% - 300px)',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      borderColor: 'transparent',
      padding: 20,
      background: 'var(--block-color)',
    },
    textarea: {
      width: 'calc(100% - 20px)',
      padding: '5px 10px',
      color: 'var(--text-color)',
      outline: 'none',
      fontFamily: 'Open-sans, sans-serif',
      lineHeight: 1.5,
      background: 'var(--block-color)',
      border: '1px solid var(--colored-element-color)',
      '&:focus': {
        border: '1px solid var(--colored-element-color)',
        background: 'rgba(255, 177, 0,0.2)'
      }
    },
    formContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10
    },
    qrCode: {
      padding: 20,
      background: '#FFF',
      boxShadow: '0px 4px 44px 3px rgba(0, 0, 0, 0.08)',
      height: 'fit-content'
    },
    colors: {
      display: 'flex',
      gap: 20,
      marginTop: 20,
      "&>div": {
        display: 'flex',
        flexDirection: 'column',
        '&>span': {
          marginBottom: 6
        },
        '&>div': {
          minHeight: 25
        }
      }
    },
    formLabel: {
      fontSize: '0.9em'
    },
    submit: {
      marginTop: 20
    }
  })

  const classes = useStyle();
  const qrCodeSize = 260;

  const downloadQRCode = () => {
    const svg = document.getElementById("qr-gen");
    const svgXML = new XMLSerializer().serializeToString(svg);
    const dataUrl = "data:image/svg," + encodeURIComponent(svgXML);

    const anchor = document.createElement("a");
    anchor.href = dataUrl;
    anchor.download = `qr-code.svg`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    <div className='tools-header'>
      <h1>QR Code generator</h1>
      <p>Générez et téléchargez le code QR d'une url ou d'un simple texte et personnalisez les couleurs d'arrière-plan et de premier plan.</p>
    </div>
    <div className={classes.container}>
      <div className={classes.block}>
        <div className={classes.formContainer}>
          <span className={classes.formLabel}>Text:</span>
          <textarea className={classes.textarea} rows='1' placeholder="Votre lien ou texte..." defaultValue={defaultUrl} onChange={handleUrl} />
        </div>
        <div style={{ marginTop: 30 }}>
          <Formfields field={{
            type: "select", component: "select", name: "harder", label: "Complexité", dataLabel: 'name', dataValue: 'id', default: difficult, handleChange: handleDifficult, data: [
              { id: "L", name: 'L' },
              { id: "M", name: 'M' },
              { id: "Q", name: 'Q' },
              { id: "H", name: 'H' }
            ],
          }} />
        </div>
        <div className={classes.colors}>
          <div>
            <span className={classes.formLabel}>Arrière-plan</span>
            <ColorPicker color={getBgColor} defaultColor={bgColor} />
          </div>
          <div>
            <span className={classes.formLabel}>Pixels</span>
            <ColorPicker color={getFgColor} defaultColor={fgColor} />
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <Formfields field={{ type: "radio", component: "switch", name: "show_image", label: "Inclure une image", default: addImage, handleChange: handleAddImage }} />
        </div>
        {Boolean(addImage) && <div style={{ marginTop: 20 }}>
          <Formfields field={{ component: "text", name: "image_url", label: "URL de l'image", default: defaultImage, value: 0, handleChange: handleImage }} />
        </div>}
      </div>
      <div className={classes.qrCode}>
        <QRCodeSVG
          id="qr-gen"
          value={url}
          size={qrCodeSize}
          bgColor={bgColor}
          fgColor={fgColor}
          level={difficult}
          includeMargin={false}
          imageSettings={addImage ? {
            src: image,
            x: (qrCodeSize - 24) / 2,
            y: (qrCodeSize - 24) / 2,
            height: 24,
            width: 24,
            excavate: true,
          } : {}}
        />
      </div>
    </div>
    <Button className={classes.submit} variant='contained' onClick={downloadQRCode}>Télécharger</Button>
  </div >
};

export default QrCode;