// 
// ─── IMPORT DES ELEMENTS NECESSAIRESS ───────────────────────────────────────
//
import React from 'react';
import UserManage from '../../containers/UserManage';

// 
// ─── DECLARATION DU COMPOSANT ───────────────────────────────────────
//
const UsersAdd = () => {
  // 
  // ─── RENDU DU COMPOSANT ───────────────────────────────────────
  //
  return <UserManage />
};

export default UsersAdd;